import Spinner from "components/Spinner";
import { setNotification } from "features/Notification/notificationSlice";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import authHeader from "services/auth-header";
import API from "services/axios";

export default function QuoteProductUpdate({
  refreshFunc,
  showModal,
  allProductsData,
}) {
  const dispatch = useDispatch();

  /*   All States
   ********************************************* */
  let [searchParams, setSearchParams] = useSearchParams();

  const [formData, setFormData] = useState({});

  const [loading, setLoading] = useState(false);

  const [selectedProduct, setSelectedProduct] = useState(null);

  let inputProperties = [
    { title: "Style No", name: "styleNo", type: "text" },
    { title: "Description", name: "description", type: "text" },
    { title: "Color", name: "color", type: "text" },
    { title: "Fabric", name: "fabric", type: "text" },
    { title: "Quantity", name: "quantity", type: "text" },
    { title: "Price", name: "price", type: "text" },
  ];

  /*   All Functions
   ********************************************* */
  const setUpdateId = (id) => {
    setSearchParams((prev) => {
      const newParams = new URLSearchParams(prev);
      newParams.set("productId", id);
      return newParams.toString();
    });
  };

  const loadingOn = () => {
    setLoading(true);
  };
  const loadingOff = () => {
    setLoading(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      loadingOn();
      const { data } = await API.patch(
        "/quote-product",
        { ...formData, price: parseFloat(formData?.price)?.toFixed(2) },
        {
          headers: authHeader(),
        }
      );
      if (data?.type === "success") {
        await refreshFunc();
        loadingOff();
        showModal(false);
        return;
      }
      if (data?.type === "error") {
        dispatch(
          setNotification({
            message: data.message,
            type: "error",
          })
        );
      }
      console.log(data);
      loadingOff();
    } catch (err) {
      loadingOff();
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
    }
  };

  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    setFormData((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const prevProduct = () => {
    let currIndex = allProductsData.indexOf(selectedProduct);
    if (currIndex === 0) {
      return;
    }
    setSelectedProduct(allProductsData[currIndex - 1]);
  };
  const nextProduct = () => {
    let currIndex = allProductsData.indexOf(selectedProduct);
    if (currIndex === allProductsData?.length - 1) {
      return;
    }
    setSelectedProduct(allProductsData[currIndex + 1]);
  };

  /*   All UseEffects
   ********************************************* */
  useEffect(() => {
    setFormData(selectedProduct);
  }, [selectedProduct]);

  useEffect(() => {
    let selectedId = searchParams.get("productId");
    if (selectedId) {
      let selectedProduct = allProductsData.find(
        (product) => product.id === parseInt(selectedId)
      );
      if (selectedProduct) {
        setSelectedProduct(selectedProduct);
      }
    }
  }, [searchParams]);

  if (!formData) {
    return <Spinner />;
  }

  return (
    <div className="px-2 pb-2">
      {loading && <Spinner />}
      <div className="d-flex justify-content-between align-items-start">
        <h3 className="px-2 mb-3">Update Product</h3>
        <div className="d-flex align-items-center gap-1">
          <button
            onClick={() => {
              prevProduct();
            }}
            className="btn btn-success btn-sm "
          >
            <i className="mdi mdi-arrow-left-bold fs-4 py-0 my-0"></i>
          </button>
          <button
            onClick={() => {
              nextProduct();
            }}
            className="btn btn-success btn-sm "
          >
            <i className="mdi mdi-arrow-right-bold fs-4 py-0 my-0"></i>
          </button>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="bg-white shadow-lg px-3 py-2 custom-border-radius">
          {inputProperties?.map((item) => (
            <div key={item.name} className="mb-2">
              <label className="form-label" htmlFor={item?.name}>
                {item?.title?.toLocaleUpperCase()}
              </label>
              <input
                value={formData[item?.name]}
                name={item.name}
                placeholder={`Enter ${item.title}`}
                type={item?.type}
                step={
                  item?.name === "price" && item?.type === "number"
                    ? 0.01
                    : undefined
                }
                className="form-control"
                onChange={handleChange}
              />
            </div>
          ))}
          <div className="mb-2">
            <label className="form-label" htmlFor={"total"}>
              Total Amount
            </label>
            <input
              value={parseFloat(
                (formData?.quantity || 0) * (formData?.price || 0)
              ).toFixed(2)}
              readOnly
              disabled
              placeholder="Enter Price & Quantity"
              type={"text"}
              className="form-control"
            />
          </div>
        </div>

        <div className="text-end mt-3">
          <button
            type="button"
            onClick={() => {
              showModal(false);
            }}
            className="btn btn-secondary me-2"
          >
            Close
          </button>
          <button type="submit" className="btn btn-success">
            UPDATE
          </button>
        </div>
      </form>
    </div>
  );
}
