import { useEffect, useState } from "react";
import ArtImageWIthSignedUrl from "./ArtImageWIthSignedUrl";

export default function ArtProductSlider({
  user,
  selectedDesign,
  setSelectedProduct,
}) {
  /*   All States
   ********************************************* */
  const [selectedProductIndex, setSelectedProductIndex] = useState(0);

  /*   All Functions
   ********************************************* */

  /*   All UseEffects
   ********************************************* */
  useEffect(() => {
    if (selectedDesign && selectedDesign?.products) {
      setSelectedProduct(
        (prev) => selectedDesign?.products[selectedProductIndex]
      );
    }
  }, [selectedDesign, selectedProductIndex]);

  return (
    <div
      className="bg-white scrollbar-hide custom-border-radius custom-shadow-sm p-2 pt-3 overflow-scroll"
      style={{ width: "13%", height: "77vh" }}
    >
      {selectedDesign?.products?.map((product, index) => (
        <div
          className="bg-white mb-2 custom-shadow-sm custom-border-radius-sm"
          onClick={() => {
            setSelectedProductIndex((prev) => index);
          }}
          key={index}
        >
          <div
            className="bg-light mx-auto flex-center p-2 position-relative"
            style={{ width: "auto", height: 100 }}
          >
            <div className="btn btn-sm btn-primary position-absolute top-0 start-0">
              {product?.id}
            </div>
            <ArtImageWIthSignedUrl
              imgStyle={{ width: "100%", height: "100%", objectFit: "contain" }}
              path={product?.images[0].img}
            />
          </div>
        </div>
      ))}
    </div>
  );
}
