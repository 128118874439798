import { useState } from "react";
import { Modal } from "react-bootstrap";

export default function ChooseProductImage({
  setFile,
  setImagePreview,
  setSelectedCatalogueProduct,
}) {
  /*   All States
   ********************************************* */
  const [showOpenFileModal, setShowOpenFileModal] = useState(null);
  const [previewFile, setPreviewFile] = useState(null);

  /*   All Functions
   ********************************************* */
  const handlePasteFile = (e) => {
    const files = e.clipboardData.files;
    if (files[0]?.type === "image/jpeg" || files[0]?.type === "image/png") {
      const imageFile = files[0];
      setFile(imageFile);
      setSelectedCatalogueProduct((prev) => {
        return { ...prev, key: null };
      });
      const imgUrl = URL.createObjectURL(imageFile);
      setPreviewFile(imgUrl);
    } else {
      return alert("Only Png and Jpeg Files are allowed.");
    }
  };

  const handleChange = (e) => {
    const imageFile = e.target.files[0];
    setFile(imageFile);
    setSelectedCatalogueProduct((prev) => {
      return { ...prev, key: null };
    });
    const imgUrl = URL.createObjectURL(imageFile);
    setPreviewFile(imgUrl);
  };

  /*   All UseEffects
   ********************************************* */
  return (
    <div>
      <button
        onClick={() => {
          setShowOpenFileModal(true);
        }}
        className="btn btn-info btn-sm"
      >
        Upload File
      </button>

      <Modal
        size="sm"
        show={showOpenFileModal}
        onHide={() => {
          setFile(null);
          setImagePreview(null);
          setShowOpenFileModal(false);
        }}
      >
        <Modal.Body
          className="custom-shadow bg-white px-3 "
          style={{ borderRadius: 15 }}
          onPaste={handlePasteFile}
        >
          <h3 className="mb-3">Upload or Paste Files</h3>
          <input
            type="file"
            autoFocus
            onChange={handleChange}
            className="form-control"
          />

          {previewFile && (
            <>
              <label className="form-label">Preview</label>

              <div className="bg-white p-2 custom-shadow-sm custom-border-radius">
                <img
                  src={previewFile}
                  className="custom-border-radius"
                  style={{ maxWidth: 230, maxHeight: 350 }}
                  alt="preview"
                />
              </div>
            </>
          )}
          <div className="mt-3 text-end">
            <button
              className="btn btn-sm me-1 btn-secondary"
              onClick={() => {
                setFile(null);
                setImagePreview(null);
                setPreviewFile(null);
                setShowOpenFileModal(false);
              }}
            >
              Close
            </button>
            <button
              className="btn btn-sm  btn-success"
              onClick={() => {
                setPreviewFile(null);
                setImagePreview(previewFile);
                setShowOpenFileModal(false);
              }}
            >
              Select
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
