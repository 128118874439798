import Spinner from "components/Spinner";
import { setNotification } from "features/Notification/notificationSlice";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import authHeader from "services/auth-header";
import API from "services/axios";

export default function UpdateAttUser({ showModal, userData, refreshFunc }) {
  /*   All States
   ********************************************* */

  const [formData, setFormData] = useState({});

  let inputFields = [
    { name: "User Name", value: "userName", type: "text" },
    { name: "Badge No", value: "badgeNo", type: "text" },
    { name: "In Time", value: "inTime", type: "time" },
    { name: "Out Time", value: "outTime", type: "time" },
    { name: "Relaxation Time", value: "relaxationTime", type: "time" },
  ];

  /*   All Functions
   ********************************************* */
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const loadingOn = () => {
    setLoading(true);
  };
  const loadingOff = () => {
    setLoading(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      loadingOn();
      const { data } = await API.patch(
        "/update-attendance-users/" + userData?.id,
        formData,
        {
          headers: authHeader(),
        }
      );
      if (data.success) {
        await refreshFunc();
        dispatch(
          setNotification({
            message: data.message,
            type: "success",
          })
        );
        showModal(false);
        loadingOff();
        return;
      }
      if (!data.success) {
        dispatch(
          setNotification({
            message: data.message,
            type: "error",
          })
        );
        showModal(false);
        loadingOff();
        return;
      }
      loadingOff();
    } catch (err) {
      loadingOff();
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
    }
  };

  /*   All UseEffects
   ********************************************* */
  useEffect(() => {
    setFormData({
      userName: userData?.userName,
      badgeNo: userData?.badgeNo,
      inTime: userData?.inTime,
      outTime: userData?.outTime,
      relaxationTime: userData?.relaxationTime,
    });
  }, [userData]);

  if (!userData?.id) {
    return dispatch(
      setNotification({
        message: "User Id is required!",
        type: "error",
      })
    );
  }

  return (
    <div>
      {loading && <Spinner />}
      <h3 className="mb-2">Update Attendance User</h3>
      <form onSubmit={handleSubmit}>
        {inputFields.map((field, index) => (
          <div key={field.value} className="mb-3">
            <label>{field.name}</label>
            <input
              name={field.value}
              type={field.type}
              placeholder={`Enter ${field.name}`}
              value={formData[field.value] || ""}
              onChange={handleChange}
              className="form-control"
            />
          </div>
        ))}
        <div className="row mb-1">
          <label className="col-4">Is Night Office?</label>
          <div className="col-8 ">
            <input
              onChange={(e) =>
                setFormData({
                  ...formData,
                  isNightOffice: e.target.checked,
                })
              }
              checked={formData?.isNightOffice}
              type="checkbox"
              id="switch0"
              data-switch="none"
            />
            <label
              htmlFor="switch0"
              data-on-label="Yes"
              data-off-label="No"
            ></label>
          </div>
        </div>

        <div className="text-end mt-3">
          <button
            onClick={(e) => {
              showModal(false);
            }}
            type="button"
            className="btn btn-secondary me-1"
          >
            Close
          </button>
          <button type="submit" className="btn btn-primary">
            Update
          </button>
        </div>
      </form>
    </div>
  );
}
