import Toast from "components/Toast/ToastComponent";
import { setNotification } from "features/Notification/notificationSlice";
import ImageWIthSignedUrl from "pages/DesignRequests/DesignRequest/ImageWIthSignedUrl";
import { useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import authHeader from "services/auth-header";
import API from "services/axios";
import ChooseProductImage from "../ChooseProductImage";
import FabricSelectModal from "../FabricSelectModal";
import ChooseCatalogueProduct from "./ChooseCatalogueProduct";

export default function SelectAProduct({
  selectedDesign,
  setSelectedDesign,
  setDesignRequests,
  setShowAddModal,
}) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user?.userInfo);

  /*   All States
   ********************************************* */
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState(null);
  const [file, setFile] = useState(null);

  const [imagePreview, setImagePreview] = useState(null);

  const [showProductCatalogueModal, setShowProductCatalogueModal] =
    useState(false);
  const [showFabricsModal, setShowFabricsModal] = useState(false);

  const [selectedCatalogueProduct, setSelectedCatalogueProduct] =
    useState(null);

  const [selectedFabric, setSelectedFabric] = useState(null);

  /*   All Functions
   ********************************************* */
  const handleCatalogueProductAdd = async (e) => {
    e.preventDefault();
    if (!file && !selectedCatalogueProduct?.key) {
      return dispatch(
        setNotification({
          message: "Please add product image",
          type: "error",
        })
      );
    }
    if (!selectedFabric) {
      return dispatch(
        setNotification({
          message: "Please select fabric.",
          type: "error",
        })
      );
    }
    if (!selectedCatalogueProduct?.styleNo) {
      return dispatch(
        setNotification({
          message: "Please select Product.",
          type: "error",
        })
      );
    }

    setIsLoading(true);

    try {
      const submitData = new FormData();
      let fileName;
      if (file) {
        submitData.append("file", file);
        fileName = file?.name?.split("/").pop();
      }
      if (selectedCatalogueProduct?.key) {
        submitData.append("fileKey", selectedCatalogueProduct?.key);
        fileName = selectedCatalogueProduct?.key?.split("/").pop();
      }

      let productId =
        selectedDesign?.products && selectedDesign?.products?.length
          ? selectedDesign?.products[selectedDesign?.products?.length - 1].id +
            1
          : 1;

      let folder = `kms/design-requests/${user?.companyId}/${selectedDesign?.id}/${productId}`;

      submitData.append("folder", folder);
      submitData.append("id", selectedDesign.id);

      // /* ******************** Updating currentData  ************************* */
      let imgUrl = `${folder}/${fileName}`;
      let newImgData = [
        {
          img: imgUrl,
          version: 1,
        },
      ];

      let newProduct = {
        id: productId,
        pdfCatalogue: selectedCatalogueProduct?.pdfCatalogue || null,
        // title: formData?.title,
        title: selectedCatalogueProduct?.styleNo,
        hasPlayerNames: formData?.hasPlayerNames,
        hasNumbers: formData?.hasNumbers,
        description: selectedCatalogueProduct?.description,
        fabrics: selectedFabric,
        categories: selectedCatalogueProduct?.categories,
        images: newImgData,
        comments: [],
      };

      let updatedDesignProducts =
        selectedDesign?.products && selectedDesign?.products?.length
          ? [...selectedDesign?.products, newProduct]
          : [newProduct];

      let updatedSelectedDesign = {
        ...selectedDesign,
        products: updatedDesignProducts,
      };

      submitData.append("products", JSON.stringify(updatedDesignProducts));

      /* ******************** Api Call  ************************* */
      const { data } = await API.post(
        "/design-request/catalogue-product",
        submitData,
        {
          headers: { ...authHeader(), "Content-Type": "multipart/form-data" },
        }
      );
      setSelectedDesign((prev) => updatedSelectedDesign);
      setDesignRequests((prev) => {
        return prev.map((item) => {
          if (item?.id === updatedSelectedDesign?.id) {
            return updatedSelectedDesign;
          } else {
            return item;
          }
        });
      });
      setIsLoading(false);
    } catch (err) {
      console.log(err.message);
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
      setIsLoading(false);
    }

    setShowAddModal(false);
  };

  /*   All UseEffects
   ********************************************* */

  return (
    <div>
      {isLoading && <Spinner />}
      <Toast />
      <div className="mt-2">
        <div className="d-flex justify-content-between align-items-center mb-2">
          <h4 className="mb-2">Select From Catalogue</h4>
          <button
            onClick={() => {
              setShowProductCatalogueModal(true);
            }}
            type="button"
            className="btn btn-sm btn-success"
          >
            CHOOSE
          </button>
        </div>

        <div className="row mb-2">
          <label className="col-5 my-auto">Style No</label>
          <div className="col-7 d-flex align-items-center gap-1">
            {selectedCatalogueProduct?.styleNo}
          </div>
        </div>
        <div className="mb-2">
          <div className="row mb-1">
            <label className="col-5">Has Player Names?</label>
            <div className="col-7 ">
              <input
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    hasPlayerNames: e.target.checked,
                  })
                }
                checked={formData?.hasPlayerNames}
                type="checkbox"
                id="switch0"
                data-switch="success"
              />
              <label
                htmlFor="switch0"
                data-on-label="Yes"
                data-off-label="No"
              ></label>
            </div>
          </div>
          <div className="row mb-1">
            <label className="col-5">Has Numbers?</label>
            <div className="col-7 ">
              <input
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    hasNumbers: e.target.checked,
                  })
                }
                checked={formData?.hasNumbers}
                type="checkbox"
                id="switch1"
                data-switch="success"
              />
              <label
                htmlFor="switch1"
                data-on-label="Yes"
                data-off-label="No"
              ></label>
            </div>
          </div>
        </div>

        <div className="row mb-1">
          <label className="col-5 my-auto">Fabric</label>
          <div className="d-flex col-7 align-items-center gap-2">
            <button
              onClick={() => {
                setShowFabricsModal(true);
              }}
              className={`btn ${
                selectedFabric ? "btn-success" : "btn-secondary"
              } btn-sm`}
            >
              Select Fabric
            </button>
          </div>
        </div>
        <div className="row mb-1">
          <label className="col-5 my-auto">Product</label>
          <div className="d-flex col-7 align-items-center gap-2">
            <ChooseProductImage
              setFile={setFile}
              setSelectedCatalogueProduct={setSelectedCatalogueProduct}
              setImagePreview={setImagePreview}
            />
            {file && imagePreview && (
              <img
                src={imagePreview}
                alt="Product"
                className="img-fluid"
                style={{ width: "50px", height: "50px" }}
              />
            )}
            {selectedCatalogueProduct?.key && (
              <ImageWIthSignedUrl
                path={selectedCatalogueProduct?.key}
                width={50}
                height={50}
              />
            )}
          </div>
        </div>

        {/*   buttons
         ********************************************* */}
        <div className="my-2">
          <div className="d-flex justify-content-end">
            <button
              onClick={() => {
                setShowAddModal(false);
              }}
              className="btn btn-secondary btn-sm"
              type="button"
            >
              Close
            </button>
            <button
              onClick={handleCatalogueProductAdd}
              className="btn btn-primary btn-sm ms-1"
              disabled={isLoading}
              type="button"
            >
              ADD
            </button>
          </div>
        </div>
      </div>
      <Modal
        size="sm"
        show={showFabricsModal}
        onHide={() => setShowFabricsModal(false)}
      >
        <Modal.Body
          className="custom-shadow bg-white px-3 "
          style={{ borderRadius: 15 }}
        >
          <FabricSelectModal
            showModal={setShowFabricsModal}
            fabricsList={
              selectedCatalogueProduct?.fabrics
                ? JSON.parse(selectedCatalogueProduct?.fabrics)
                : null
            }
            setSelectedFabric={setSelectedFabric}
          />
        </Modal.Body>
      </Modal>
      <Modal
        size="lg"
        show={showProductCatalogueModal}
        onHide={() => setShowProductCatalogueModal(false)}
      >
        <Modal.Body
          className="shadow-lg bg-white px-3 "
          style={{ borderRadius: 15 }}
        >
          <ChooseCatalogueProduct
            setFile={setFile}
            setImagePreview={setImagePreview}
            setSelectedCatalogueProduct={setSelectedCatalogueProduct}
            showModal={setShowProductCatalogueModal}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}
