import Spinner from "components/Spinner";
import { setNotification } from "features/Notification/notificationSlice";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import authHeader from "services/auth-header";
import API from "services/axios";

export default function AddNewVersion({
  setShowAddModal,
  selectedProduct,
  selectedDesign,
  setDesignRequests,
  setSelectedDesign,
  setSelectedProduct,
}) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user?.userInfo);
  /*   All States
   ********************************************* */
  const [formData, setFormData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [inputEmail, setInputEmail] = useState("");

  const [emailList, setEmailList] = useState([]);

  /*   All Functions
   ********************************************* */
  const addNewVersion = async (e, formData) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const submitData = new FormData();
      submitData.append("file", formData?.file);
      let folder = `kms/design-requests/${user?.companyId}/${
        selectedDesign?.customerId
      }/${selectedDesign?.title.replaceAll(
        " ",
        "_"
      )}/${selectedProduct?.title.replaceAll(" ", "_")}`;

      submitData.append("locationId", selectedDesign?.customer?.locationId);
      submitData.append(
        "locationLogo",
        selectedDesign?.customer?.location?.logo
      );
      submitData.append("mockupTitle", selectedDesign?.title);
      submitData.append("emailList", JSON.stringify(emailList));
      submitData.append("folder", folder);
      submitData.append("id", selectedDesign.id);

      /* ******************** Updating currentData  ************************* */
      let imgUrl = `${folder}/${formData?.file?.name}`;
      let newImgData = [
        {
          img: imgUrl,
          version: selectedProduct.images?.length + 1,
        },
        ...selectedProduct.images,
      ];
      let updatedSelectedProduct = { ...selectedProduct, images: newImgData };
      let updatedDesignProducts = selectedDesign?.products?.map((product) => {
        if (product.id === updatedSelectedProduct?.id) {
          return updatedSelectedProduct;
        }
        return product;
      });

      let updatedSelectedDesign = {
        ...selectedDesign,
        products: updatedDesignProducts,
      };

      submitData.append("products", JSON.stringify(updatedDesignProducts));

      /* ******************** Api Call  ************************* */
      const { data } = await API.post(
        "/design-request/new-version",
        submitData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      setSelectedProduct((prev) => updatedSelectedProduct);
      setSelectedDesign((prev) => updatedSelectedDesign);
      setDesignRequests((prev) => {
        return prev.map((item) => {
          if (item?.id === updatedSelectedDesign?.id) {
            return updatedSelectedDesign;
          } else {
            return item;
          }
        });
      });
      setIsLoading(false);
    } catch (err) {
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );

      setIsLoading(false);
    }

    setShowAddModal(false);
  };

  function addEmail(e) {
    e.preventDefault();
    if (!inputEmail) {
      return;
    }
    setEmailList((prev) => [...prev, inputEmail]);
    setInputEmail("");
  }

  function deleteEmail(item) {
    setEmailList((prev) => prev.filter((email) => email !== item));
  }

  function editEmail(item) {
    setInputEmail(item);
    setEmailList((prev) => prev.filter((email) => email !== item));
  }

  /*   All UseEffects
   ********************************************* */
  useEffect(() => {
    if (selectedDesign?.guestList && selectedDesign?.guestList?.length) {
      setEmailList(
        typeof selectedDesign?.guestList === "string"
          ? JSON.parse(selectedDesign?.guestList)
          : selectedDesign?.guestList
      );
    }
  }, []);

  return (
    <div>
      {isLoading && <Spinner />}
      <div className="">
        <h3>ADD New Version ( width 950px ) </h3>
      </div>
      <hr className="mt-0" />
      <div className="row">
        <div className="col-3">
          <h4>Email List</h4>
          <form
            onSubmit={addEmail}
            className="d-flex gap-2 mb-2 justify-content-between align-items-center"
          >
            <input
              type="email"
              value={inputEmail}
              onChange={(e) => setInputEmail(e.target.value)}
              placeholder="Add new email"
              className="form-control"
            />
            <button
              type="submit"
              disabled={!inputEmail}
              className="btn btn-success"
            >
              ADD
            </button>
          </form>
          {emailList?.map((item) => (
            <div
              className="d-flex justify-content-between align-items-center mb-1"
              key={item}
            >
              <div>{item}</div>
              <div className="">
                <button
                  onClick={() => {
                    editEmail(item);
                  }}
                  className="btn btn-sm px-1 py-0 btn-info me-1"
                >
                  <i className="mdi mdi-pencil"></i>
                </button>
                <button
                  onClick={() => {
                    deleteEmail(item);
                  }}
                  className="btn btn-sm px-1 py-0 btn-danger"
                >
                  <i className="mdi mdi-delete"></i>
                </button>
              </div>
            </div>
          ))}
        </div>
        <div className="col-8">
          <form
            onSubmit={(e) => {
              addNewVersion(e, formData);
            }}
          >
            <div className=" mb-3">
              <h4 className="form-label">Upload File</h4>
              <div className="">
                <input
                  type="file"
                  required
                  onChange={(e) => {
                    setFormData((prev) => {
                      return { ...prev, file: e.target.files[0] };
                    });
                  }}
                  className="form-control"
                  placeholder="Enter Title"
                />
              </div>
            </div>
            {/*   buttons
             ********************************************* */}
            <div className="my-2">
              <div className="d-flex justify-content-end">
                <button
                  onClick={() => {
                    setShowAddModal(false);
                  }}
                  className="btn btn-secondary btn-sm"
                  type="button"
                >
                  Close
                </button>
                <button className="btn btn-primary btn-sm ms-1" type="submit">
                  ADD
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
