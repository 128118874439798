import Spinner from "components/Spinner";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomProduct from "./CustomProduct/CustomProduct";
import SelectAProduct from "./SelectAProduct/SelectAProduct";

export default function AddNewProduct({
  setShowAddModal,
  selectedProduct,
  selectedDesign,
  setDesignRequests,
  setSelectedDesign,
  setSelectedProduct,
}) {
  /*   All States
   ********************************************* */
  const [activeTab, setActiveTab] = useState("select product");

  /*   All Functions
   ********************************************* */

  /*   All UseEffects
   ********************************************* */

  return (
    <div>
      <div className="">
        <h4>ADD NEW PRODUCT</h4>
      </div>
      <hr className="mt-0 " />
      <ul className="nav nav-tabs nav-bordered border-0 d-flex flex-grow-1 pb-2 ">
        <li
          className="nav-item "
          onClick={() => {
            setActiveTab("select product");
          }}
        >
          <button
            className={
              activeTab === "select product"
                ? `nav-link rounded-3 active bg-primary text-white`
                : "nav-link rounded-3 bg-white"
            }
          >
            Select A Product
          </button>
        </li>
        <li
          className="nav-item "
          onClick={() => {
            setActiveTab("custom Product");
          }}
        >
          <button
            className={
              activeTab === "custom Product"
                ? `nav-link rounded-3 active bg-primary text-white`
                : "nav-link rounded-3 bg-white"
            }
          >
            Custom Product
          </button>
        </li>
      </ul>

      {activeTab === "select product" && (
        <SelectAProduct
          selectedDesign={selectedDesign}
          setSelectedDesign={setSelectedDesign}
          setDesignRequests={setDesignRequests}
          setShowAddModal={setShowAddModal}
        />
      )}

      {activeTab === "custom Product" && (
        <CustomProduct
          selectedDesign={selectedDesign}
          setSelectedDesign={setSelectedDesign}
          setDesignRequests={setDesignRequests}
          setShowAddModal={setShowAddModal}
        />
      )}
    </div>
  );
}
