import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import ImageWIthSignedUrl from "../../../ImageWIthSignedUrl";
import AddNewProduct from "./AddNewProduct";

export default function ProductSlider({
  selectedDesign,
  setSelectedProduct,
  selectedProduct,
  setSelectedDesign,
  setDesignRequests,
}) {
  /*   All States
   ********************************************* */
  const [selectedProductIndex, setSelectedProductIndex] = useState(0);
  const [showAddModal, setShowAddModal] = useState(false);

  /*   All Functions
   ********************************************* */

  /*   All UseEffects
   ********************************************* */
  useEffect(() => {
    if (selectedDesign?.products) {
      setSelectedProduct(
        (prev) => selectedDesign?.products[selectedProductIndex]
      );
    }
  }, [selectedDesign, selectedProductIndex]);

  return (
    <div
      className="bg-white scrollbar-hide custom-border-radius shadow-lg p-2 pt-3 overflow-scroll "
      style={{ height: "72vh", flexShrink: 0 }}
    >
      <button
        onClick={() => {
          setShowAddModal(true);
        }}
        className="btn btn-success mb-3 btn-sm w-100"
      >
        Add Product
      </button>
      {selectedDesign?.products?.map((product, index) => (
        <div
          className="bg-white mb-2 custom-shadow-sm custom-border-radius-sm"
          onClick={() => {
            setSelectedProductIndex((prev) => index);
          }}
          key={index}
        >
          <div
            className="bg-light flex-center p-2 position-relative"
            style={{ width: "auto", height: 100 }}
          >
            <div className="btn btn-sm btn-primary position-absolute top-0 start-0">
              {product?.id}
            </div>
            <ImageWIthSignedUrl
              imgStyle={{ width: "100%", height: "100%", objectFit: "cover" }}
              path={product?.images[0].img}
            />
          </div>
        </div>
      ))}
      <Modal show={showAddModal} onHide={() => setShowAddModal(false)}>
        <Modal.Body
          className="shadow-lg bg-white px-3 "
          style={{ borderRadius: 15 }}
        >
          <AddNewProduct
            setShowAddModal={setShowAddModal}
            selectedProduct={selectedProduct}
            setSelectedProduct={setSelectedProduct}
            selectedDesign={selectedDesign}
            setSelectedDesign={setSelectedDesign}
            setDesignRequests={setDesignRequests}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}
