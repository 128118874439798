import Spinner from "components/Spinner";
import Toast from "components/Toast/ToastComponent";
import { setNotification } from "features/Notification/notificationSlice";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import authHeader from "services/auth-header";
import API from "services/axios";

export default function EmailGuestList({
  selectedCustomerId,
  setSelectedDesign,
  setDesignRequests,
  selectedDesign,
  customerContacts,
  showModal,
}) {
  const dispatch = useDispatch();

  /*   All States
   ********************************************* */
  const [loading, setLoading] = useState(false);
  const [guestList, setGuestList] = useState([]);

  /*   All Functions
   ********************************************* */
  const loadingOn = () => {
    setLoading(true);
  };
  const loadingOff = () => {
    setLoading(false);
  };

  const handleChecked = (item) => {
    let email = JSON.parse(item?.data).email;
    // add item.email to guestList if not already present and remove if present
    if (!guestList?.includes(email)) {
      setGuestList([...(guestList || []), email]);
    } else {
      setGuestList(guestList?.filter((i) => i !== email));
    }
  };

  const handleSaveGuestList = async () => {
    try {
      loadingOn();
      const { data } = await API.patch(
        "/design-request/update",
        {
          formData: {
            guestList: JSON.stringify(guestList),
            id: selectedDesign?.id,
          },
        },
        { headers: authHeader() }
      );
      if (data?.success) {
        setDesignRequests((prev) => {
          return prev.map((item) => {
            if (item.id === selectedDesign.id) {
              let updatedItem = {
                ...item,
                guestList: JSON.stringify(guestList),
              };
              setSelectedDesign(updatedItem);
              return updatedItem;
            } else {
              return item;
            }
          });
        });
        dispatch(setNotification({ type: "success", message: data?.message }));
        showModal(false);
      }
      loadingOff();
    } catch (err) {
      loadingOff();
      console.log(err.message);
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
    }
  };

  /*   All UseEffects
   ********************************************* */

  useEffect(() => {
    if (selectedDesign) {
      setGuestList(
        typeof selectedDesign?.guestList === "string"
          ? JSON.parse(selectedDesign?.guestList)
          : []
      );
    }
  }, [selectedDesign]);

  return (
    <div>
      {loading && <Spinner />}
      <Toast />
      <h3>Guest List</h3>
      <table class="table table-responsive w-full">
        <thead>
          <tr>
            <th scope="col">Action</th>
            <th scope="col">Name</th>
            <th scope="col">Email</th>
          </tr>
        </thead>
        <tbody>
          {customerContacts &&
            customerContacts.map((item, i) => (
              <tr key={item?.id}>
                <th scope="row">
                  <input
                    onChange={() => {
                      handleChecked(item);
                    }}
                    checked={guestList?.includes(JSON.parse(item?.data).email)}
                    type="checkbox"
                  />
                </th>
                <td>{`${JSON.parse(item?.data).firstName} ${
                  JSON.parse(item?.data).lastName
                }`}</td>
                <td>{JSON.parse(item?.data).email}</td>
              </tr>
            ))}
        </tbody>
      </table>
      <div className="text-end">
        <button
          onClick={() => {
            showModal(false);
          }}
          className="btn btn-sm btn-secondary"
        >
          Close
        </button>
        <button
          onClick={handleSaveGuestList}
          className="btn btn-sm btn-success ms-1"
        >
          Save
        </button>
      </div>
    </div>
  );
}
