import AttReportBtn from "components/Pdfs/Attendance/AttReportBtn";
import Spinner from "components/Spinner";
import { setNotification } from "features/Notification/notificationSlice";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import authHeader from "services/auth-header";
import API from "services/axios";

export default function GenerateAttendance({ deviceId, userData }) {
  const dispatch = useDispatch();

  /*   All States
   ********************************************* */
  const [showModal, setShowModal] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState("");
  const [loading, setLoading] = useState(false);

  const [selectedReport, setSelectedReport] = useState(null);

  /*   All Functions
   ********************************************* */
  const loadingOn = () => {
    setLoading(true);
  };
  const loadingOff = () => {
    setLoading(false);
  };

  const handleSelectMonth = (event) => {
    setSelectedReport(null);
    setSelectedMonth(event.target.value);
  };

  function calculateHoursWorked(inTime, outTime) {
    const t1 = parseTime(inTime);
    const t2 = parseTime(outTime);

    const startDate = new Date();
    const endDate = new Date();

    startDate.setHours(t1.hours, t1.minutes, t1.seconds);
    endDate.setHours(t2.hours, t2.minutes, t2.seconds);

    const differenceInMilliseconds = endDate - startDate;

    const millisecondsInHour = 1000 * 60 * 60;
    const millisecondsInMinute = 1000 * 60;
    const millisecondsInSecond = 1000;

    const hours =
      t1.hours === t2.hours
        ? 0
        : Math.floor(differenceInMilliseconds / millisecondsInHour);
    // const hours = Math.floor(differenceInMilliseconds / millisecondsInHour);
    const minutes = Math.floor(
      (differenceInMilliseconds % millisecondsInHour) / millisecondsInMinute
    );
    const seconds = Math.floor(
      (differenceInMilliseconds % millisecondsInMinute) / millisecondsInSecond
    );

    return { hours, minutes, seconds };
  }

  function calculateLateHours(relaxationTime, inTime) {
    const t1 = parseTime(relaxationTime);
    const t2 = parseTime(inTime);

    const startDate = new Date();
    const endDate = new Date();

    startDate.setHours(t1.hours, t1.minutes, t1.seconds);
    endDate.setHours(t2.hours, t2.minutes, t2.seconds);

    const differenceInMilliseconds = endDate - startDate;

    const millisecondsInHour = 1000 * 60 * 60;
    const millisecondsInMinute = 1000 * 60;
    const millisecondsInSecond = 1000;

    const hours =
      t1.hours === t2.hours || t2.hours < t1.hours
        ? 0
        : Math.floor(differenceInMilliseconds / millisecondsInHour);
    // const hours = Math.floor(differenceInMilliseconds / millisecondsInHour);
    const minutes =
      t1.hours === t2.minutes || t2.minutes < t1.minutes
        ? 0
        : Math.floor(
            (differenceInMilliseconds % millisecondsInHour) /
              millisecondsInMinute
          );
    const seconds = Math.floor(
      (differenceInMilliseconds % millisecondsInMinute) / millisecondsInSecond
    );

    return { hours, minutes, seconds };
  }

  const getDayNameByDate = (rawDate) => {
    let date = typeof rawDate === "date" ? rawDate : new Date(rawDate);
    const days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    const dayOfWeek = days[date.getUTCDay()];
    return dayOfWeek;
  };

  function getDatesArrByMonth(monthYear) {
    const [month, year] = monthYear.split("-").map(Number);
    const date = new Date(year, month - 1, 1);
    const dates = [];

    while (date.getMonth() === month - 1) {
      const day = date.getDate();
      dates.push(`${day}-${month}-${year}`);
      date.setDate(date.getDate() + 1);
    }

    return dates;
  }

  function parseTime(timeString) {
    const [hours, minutes, seconds] = timeString.split(":").map(Number);
    return { hours, minutes, seconds };
  }

  /**
   * Parses a time string in the format "HH:MM:SS" into an object with hours, minutes, and seconds.
   * If seconds are not provided, they default to 0.
   * @param {string} timeString - The time string to parse.
   * @returns {Object} An object containing hours, minutes, and seconds.
   */
  function parseTime(timeString) {
    const [hours, minutes, seconds = 0] = timeString.split(":").map(Number);
    return { hours, minutes, seconds };
  }

  /**
   * Calculates the time difference between two time strings.
   * If the second time is later than the first, the result is negative.
   * @param {string} time1 - The first time string.
   * @param {string} time2 - The second time string.
   * @returns {Object} An object containing the difference in hours, minutes, and seconds.
   */
  function calculateTimeDifference(time1, time2) {
    const t1 = parseTime(time1); // Parse the first time
    const t2 = parseTime(time2); // Parse the second time
    const date1 = new Date(); // Create a Date object for the first time
    const date2 = new Date(); // Create a Date object for the second time

    // Set the hours, minutes, and seconds for both Date objects
    date1.setHours(t1.hours, t1.minutes, t1.seconds);
    date2.setHours(t2.hours, t2.minutes, t2.seconds);

    // Calculate the difference in milliseconds between the two times
    const differenceInMilliseconds = date1 - date2;
    const millisecondsInHour = 1000 * 60 * 60; // Number of milliseconds in an hour
    const millisecondsInMinute = 1000 * 60; // Number of milliseconds in a minute
    const millisecondsInSecond = 1000; // Number of milliseconds in a second

    // Determine if the difference is negative
    const isNegative = differenceInMilliseconds < 0;
    // Calculate the absolute difference in milliseconds
    const absoluteDifference = Math.abs(differenceInMilliseconds);

    // Calculate the hours, minutes, and seconds from the absolute difference
    const hours = Math.floor(absoluteDifference / millisecondsInHour);
    let minutes = Math.floor(
      (absoluteDifference % millisecondsInHour) / millisecondsInMinute
    );
    const seconds = Math.floor(
      (absoluteDifference % millisecondsInMinute) / millisecondsInSecond
    );

    // Apply the negative sign to the hours and minutes if the difference is negative
    if (isNegative) {
      return { hours: -hours, minutes: -(minutes + 1), seconds };
    }

    return { hours, minutes: minutes, seconds };
  }

  function calculateOverTime({ outTime, userOutTime }) {
    let OvertimeOutTime = calculateTimeDifference(outTime, userOutTime);

    return OvertimeOutTime;
  }

  /** * Converts a time object with hours and minutes into decimal hours.
   * @param {Object} timeObj - An object containing hours and minutes.
   * @param {number} timeObj.hours - The number of hours.
   *  @param {number} timeObj.minutes - The number of minutes.
   * @returns {number} The time in decimal hours.
   * **/
  function convertToDecimalHours(timeObj) {
    const { hours, minutes } = timeObj;
    // Destructure hours and minutes from the time object
    const decimalHours = hours + minutes / 60; // Convert minutes to decimal and add to hours
    let isNegative =
      Math.sign(hours) === -1 || Math.sign(minutes) === -1 ? true : false;
    return isNegative ? decimalHours * -1 : decimalHours;
  }

  //  Calculate In-out total-hours total overtime etc.
  const getCalculatedAttendanceNightOfficeData = (
    curDateRecords,
    nextDateRecords
  ) => {
    if (!nextDateRecords) {
      return {};
    }

    const sortedCurrDateRecords = curDateRecords.sort(
      (a, b) => new Date(a.recordTime) - new Date(b.recordTime)
    );
    const sortedNextDateRecords = nextDateRecords.sort(
      (a, b) => new Date(a.recordTime) - new Date(b.recordTime)
    );

    const firstEntry = sortedCurrDateRecords.slice(-1);
    const lastEntry = sortedNextDateRecords.slice(0, 1);

    const date = new Date(firstEntry[0].recordTime);
    const day = date.getDate();

    let inTime = firstEntry[0]?.formattedTime;
    let relaxationTime = userData?.relaxationTime;

    let outTime = lastEntry[0]?.formattedTime;
    let formattedDate = firstEntry[0]?.formattedDate;

    let dayOfWeek = day;
    let dayNameOfWeek = getDayNameByDate(firstEntry[0]?.recordTime);
    let userInTime = userData?.inTime;
    let userOutTime = userData?.outTime;

    /* =================== Get Total Hours */
    // Get hours after endOfDay of outTime
    const extraHoursAfter12 = calculateHoursWorked("00:00:00", outTime);

    // Get starting Hours of inTime and End of Day
    const startingHours = calculateHoursWorked(inTime, "23:59:59");

    // Combine startingHours and extraHoursAfter12
    const totalHours = {
      hours: startingHours.hours + extraHoursAfter12.hours,
      minutes: startingHours.minutes + extraHoursAfter12.minutes,
    };

    let overTimeObj = calculateOverTime({
      outTime,
      userOutTime,
    });

    let totalHoursObj = `${totalHours.hours}:${totalHours.minutes}`;

    let totalLateTime = relaxationTime
      ? calculateLateHours(relaxationTime, inTime)
      : calculateHoursWorked(userInTime, inTime);

    let totalLateTimeValue = `${
      Math.sign(totalLateTime.hours) === -1 ? 0 : totalLateTime.hours
    }:${Math.sign(totalLateTime.minutes) === -1 ? 0 : totalLateTime.minutes}`;

    let overTimeValue = `${
      overTimeObj.hours < 0 || overTimeObj.minutes < 0 ? "-" : ""
    }${Math.abs(overTimeObj.hours)}:${Math.abs(overTimeObj.minutes)}`;

    return {
      inTime,
      outTime,
      formattedDate,
      dayOfWeek,
      inRecord: firstEntry[0].recordTime,
      outRecord: lastEntry[0].recordTime,
      dayNameOfWeek,
      userInTime,
      userOutTime,

      totalLateTime: inTime === outTime ? "00:00" : totalLateTimeValue,
      totalHours: inTime === outTime ? "00:00" : totalHoursObj,
      overTime: inTime === outTime ? "00:00" : overTimeValue,
    };
  };

  //  Calculate In-out total-hours total overtime etc.
  const getCalculatedAttendanceData = (arr) => {
    const sortedArr = arr.sort(
      (a, b) => new Date(a.recordTime) - new Date(b.recordTime)
    );

    const firstEntry = sortedArr.slice(0, 1);
    const lastEntry = sortedArr.slice(-1);

    const date = new Date(firstEntry[0].recordTime);
    const day = date.getDate();

    let inTime = firstEntry[0]?.formattedTime;
    let relaxationTime = userData?.relaxationTime;

    let outTime = lastEntry[0]?.formattedTime;
    let formattedDate = firstEntry[0]?.formattedDate;
    let dayOfWeek = day;
    let dayNameOfWeek = getDayNameByDate(firstEntry[0]?.recordTime);
    let userInTime = userData?.inTime;
    let userOutTime = userData?.outTime;

    let totalHoursObj = calculateTimeDifference(outTime, inTime);

    let totalLateTime = relaxationTime
      ? calculateLateHours(relaxationTime, inTime)
      : calculateHoursWorked(userInTime, inTime);

    let overTimeObj = calculateOverTime({
      outTime,
      userOutTime,
    });

    let totalLateTimeValue =
      Math.sign(totalLateTime.hours) === -1
        ? 0
        : parseFloat(convertToDecimalHours(totalLateTime).toFixed(2));

    let totalHoursValue = parseFloat(
      convertToDecimalHours(totalHoursObj).toFixed(2)
    );

    let overTimeValue =
      overTimeObj.hours < 0 || overTimeObj.minutes < 0
        ? parseFloat((convertToDecimalHours(overTimeObj) * -1).toFixed(2))
        : parseFloat(convertToDecimalHours(overTimeObj).toFixed(2));

    return {
      inTime,
      outTime,
      formattedDate,
      dayOfWeek,
      inRecord: firstEntry[0].recordTime,
      outRecord: lastEntry[0].recordTime,
      dayNameOfWeek,
      userInTime,
      userOutTime,

      totalLateTime: inTime === outTime ? 0.0 : parseFloat(totalLateTimeValue),
      totalHours: inTime === outTime ? 0.0 : parseFloat(totalHoursValue),
      overTime: inTime === outTime ? 0.0 : parseFloat(overTimeValue),
    };
  };

  //  Calculate In-Out Records of each Date For night office employee
  const getParsedAttendanceNightOfficeRecord = (attendanceRecords) => {
    setSelectedReport(null);
    const recordsByDay = {};
    let recordMonth = new Date(selectedMonth);
    let monthYear = `${
      recordMonth.getMonth() + 1
    }-${recordMonth.getFullYear()}`;

    attendanceRecords.forEach((record) => {
      const date = new Date(record.recordTime);

      // Convert to local time
      const options = {
        timeZone: "Asia/Karachi",
        hour12: false,
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      };
      const localTime = date.toLocaleString("en-GB", options);
      const formattedTime = localTime;

      // Get dd-mm-yyyy format date
      const formattedDate = `${
        date.getMonth() + 1
      }-${date.getDate()}-${date.getFullYear()}`;

      // check if recordsByDay[day] has previous value.
      recordsByDay[formattedDate] = (recordsByDay[formattedDate] || []).concat({
        ...record,
        formattedTime,
        formattedDate,
        dayOfWeek: getDayNameByDate(date),
      });
    });

    // Get In-Out Records of each Date
    const parsedRecords = Object.keys(recordsByDay).reduce((prev, key) => {
      const records = recordsByDay[key];

      let nextDate = new Date(key);
      nextDate.setDate(nextDate.getDate() + 1);

      let formattedNextDate = `${
        nextDate.getMonth() + 1
      }-${nextDate.getDate()}-${nextDate.getFullYear()}`;

      prev[key] = getCalculatedAttendanceNightOfficeData(
        records,
        recordsByDay[formattedNextDate]
      );
      return prev;
    }, {});

    // Only get those records which are of selected month eg. 08-01-2024 to 08-31-2024 if there are 31 days.
    const filteredRecords = Object.entries(parsedRecords)
      .filter(([key]) => {
        const [month, day, year] = key.split("-").map(Number);
        const date = new Date(year, month - 1, day);
        return (
          date.getMonth() === recordMonth.getMonth() &&
          date.getFullYear() === recordMonth.getFullYear()
        );
      })
      .reduce((obj, [key, value]) => {
        obj[key] = value;
        return obj;
      }, {});

    // //  Check if there is any record in filteredRecords
    if (Object.keys(filteredRecords).length === 0) {
      setSelectedReport({
        attendanceRecords: {},
        userData,
        monthYear,
        summaryData: {
          totalLateTime: "00:00",
          totalOverTime: "00:00",
          totalWorkingHours: "00:00",
        },
      });
      return;
    }

    // Also calculate totalLateTime totalOverTime totalWorkingHours
    const summaryData = Object.values(filteredRecords).reduce(
      (prev, curr) => {
        if (Object.keys(curr).length === 0) {
          return prev;
        }

        let lateHours = parseInt(curr.totalLateTime.split(":")[0]);
        let lateMinutes = parseInt(curr.totalLateTime.split(":")[1]);

        prev.totalLateTime = {
          hours: prev.totalLateTime.hours + lateHours,
          minutes: prev.totalLateTime.minutes + lateMinutes,
        };

        let overTimeHours = curr.overTime.startsWith("-")
          ? parseInt(curr.overTime.split(":")[0]) * -1
          : parseInt(curr.overTime.split(":")[0]);
        let overTimeMinutes = curr.overTime.startsWith("-")
          ? parseInt(curr.overTime.split(":")[1]) * -1
          : parseInt(curr.overTime.split(":")[1]);

        prev.totalOverTime = {
          hours: prev.totalOverTime.hours + overTimeHours,
          minutes: prev.totalOverTime.minutes + overTimeMinutes,
        };

        let workingHours = parseInt(curr.totalHours.split(":")[0]);
        let workingMinutes = parseInt(curr.totalHours.split(":")[1]);

        prev.totalWorkingHours = {
          hours: prev.totalWorkingHours.hours + workingHours,
          minutes: prev.totalWorkingHours.minutes + workingMinutes,
        };

        return prev;
      },
      {
        totalLateTime: { hours: 0, minutes: 0 },
        totalOverTime: { hours: 0, minutes: 0 },
        totalWorkingHours: { hours: 0, minutes: 0 },
      }
    );

    //Adjust minutes and hours for summaryData
    const adjustTime = (time) => {
      time.hours += Math.floor(time.minutes / 60);
      time.minutes %= 60;
    };

    adjustTime(summaryData.totalLateTime);
    adjustTime(summaryData.totalOverTime);
    adjustTime(summaryData.totalWorkingHours);

    let parsedSummaryData = {
      totalLateTime: `${summaryData.totalLateTime.hours}:${summaryData.totalLateTime.minutes}`,
      totalOverTime: `${summaryData.totalOverTime.hours}:${summaryData.totalOverTime.minutes}`,
      totalWorkingHours: `${summaryData.totalWorkingHours.hours}:${summaryData.totalWorkingHours.minutes}`,
    };

    console.log({
      attendanceRecords: filteredRecords,
      userData,
      monthYear,
      summaryData: parsedSummaryData,
    });

    setSelectedReport({
      attendanceRecords: filteredRecords,
      userData,
      monthYear,
      summaryData: parsedSummaryData,
    });

    return {
      attendanceRecords: filteredRecords,
      userData,
      monthYear,
      summaryData: parsedSummaryData,
    };
  };

  //  Calculate In-Out Records of each Date
  const getParsedAttendanceRecord = (attendanceRecords) => {
    setSelectedReport((prev) => null);
    const recordsByDay = {};
    let recordMonth = new Date(selectedMonth);
    let monthYear = `${
      recordMonth.getMonth() + 1
    }-${recordMonth.getFullYear()}`;

    attendanceRecords.forEach((record) => {
      const date = new Date(record.recordTime);

      // Convert to local time
      const options = {
        timeZone: "Asia/Karachi",
        hour12: false,
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      };
      const localTime = date.toLocaleString("en-GB", options);

      const formattedTime = localTime;

      // Get dd-mm-yyyy format date
      const formattedDate = `${
        date.getMonth() + 1
      }-${date.getDate()}-${date.getFullYear()}`;

      // check if recordsByDay[day] has previous value.
      recordsByDay[formattedDate] = (recordsByDay[formattedDate] || []).concat({
        ...record,
        formattedTime,
        formattedDate,
        dayOfWeek: getDayNameByDate(date),
      });
    });

    // Get In-Out Records of each Date
    const parsedRecords = Object.keys(recordsByDay).reduce((prev, key) => {
      const records = recordsByDay[key];

      prev[key] = getCalculatedAttendanceData(records);
      return prev;
    }, {});

    // Only get those records which are of selected month eg. 08-01-2024 to 08-31-2024 if there are 31 days.
    const filteredRecords = Object.entries(parsedRecords)
      .filter(([key]) => {
        const [month, day, year] = key.split("-").map(Number);
        const date = new Date(year, month - 1, day);
        return (
          date.getMonth() === recordMonth.getMonth() &&
          date.getFullYear() === recordMonth.getFullYear()
        );
      })
      .reduce((obj, [key, value]) => {
        obj[key] = value;
        return obj;
      }, {});

    // Check if there is any record in filteredRecords
    if (Object.keys(filteredRecords).length === 0) {
      setSelectedReport((prev) => ({
        attendanceRecords: {},
        userData,
        monthYear,
        summaryData: {
          totalLateTime: "00:00",
          totalOverTime: "00:00",
          totalWorkingHours: "00:00",
        },
      }));
      return;
    }

    // Also calculate totalLateTime totalOverTime totalWorkingHours
    const summaryData = Object.values(filteredRecords).reduce(
      (prev, curr) => {
        if (Object.keys(curr).length === 0) {
          return prev;
        }

        console.log(curr.totalLateTime);

        // Add totalLateTime totalOverTime totalWorkingHours
        prev.totalLateTime = parseFloat(
          (prev.totalLateTime + curr.totalLateTime).toFixed(2)
        );
        prev.totalOverTime = parseFloat(
          (prev.totalOverTime + curr.overTime).toFixed(2)
        );
        prev.totalWorkingHours = parseFloat(
          (prev.totalWorkingHours + curr.totalHours).toFixed(2)
        );

        return prev;
      },
      {
        totalLateTime: 0.0,
        totalOverTime: 0.0,
        totalWorkingHours: 0.0,
      }
    );

    let parsedSummaryData = {
      totalLateTime: summaryData.totalLateTime,
      totalOverTime: summaryData.totalOverTime,
      totalWorkingHours: summaryData.totalWorkingHours,
    };

    console.log({
      attendanceRecords: filteredRecords,
      userData,
      monthYear,
      summaryData: parsedSummaryData,
    });

    setSelectedReport({
      attendanceRecords: filteredRecords,
      userData,
      monthYear,
      summaryData: parsedSummaryData,
    });

    return {
      attendanceRecords: filteredRecords,
      userData,
      monthYear,
      summaryData: parsedSummaryData,
    };
  };

  const getLogsByDate = async ({ startDate, endDate }) => {
    try {
      if (!userData.inTime || !userData.outTime) {
        return dispatch(
          setNotification({
            message: "Please Enter User In Time and Out Time",
            type: "error",
          })
        );
      }
      loadingOn();
      const { data } = await API.post(
        "/get-filter-logs-users/" + userData?.userId,
        {
          deviceId,
          startDate,
          endDate,
        },
        {
          headers: authHeader(),
        }
      );

      userData?.isNightOffice
        ? getParsedAttendanceNightOfficeRecord(data?.attendanceLog)
        : getParsedAttendanceRecord(data?.attendanceLog);

      // setUserData(data?.userData);
      // setUserAttendanceLogs(data?.attendanceLog);
      loadingOff();
    } catch (err) {
      loadingOff();
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
    }
  };

  const GenerateReport = async (e) => {
    if (!selectedMonth) {
      dispatch(
        setNotification({
          message: "Please Select Month",
          type: "error",
        })
      );
      return;
    }

    // Get Start of month and end of month
    const selectedDate = new Date(selectedMonth);
    const startOfMonth = new Date(
      selectedDate.getFullYear(),
      selectedDate.getMonth(),
      1
    );

    const endOfMonth = new Date(
      selectedDate.getFullYear(),
      selectedDate.getMonth() + 1,
      2
    );

    console.log(endOfMonth);

    // // Increment 2 day if userData.isNightOffice is true
    if (userData?.isNightOffice) {
      endOfMonth.setDate(endOfMonth.getDate() + 1);
    }

    // console.log(endOfMonth);

    getLogsByDate({
      startDate: startOfMonth,
      endDate: endOfMonth,
    });
  };

  /*   All UseEffects
   ********************************************* */
  useEffect(() => {
    let date = new Date("9-1-2024");
    let month = date.getMonth();
    let year = date.getFullYear();

    getDatesArrByMonth(`${month + 1}-${year}`);
  }, []);
  return (
    <>
      <button
        onClick={(e) => {
          setShowModal(true);
        }}
        className="btn btn-info ms-1"
      >
        Generate Attendance
      </button>
      <Modal
        contentClassName="bg-transparent"
        show={showModal}
        onHide={() => {
          setShowModal(false);
        }}
      >
        <Modal.Body className="bg-white custom-border-radius px-4">
          <div>
            {loading && <Spinner />}
            <h3 className="mb-3">Generate Attendance Report</h3>
            <div>
              <label className="form-label">Select Month</label>
              <input
                className="form-control"
                type="month"
                value={selectedMonth || ""}
                onChange={handleSelectMonth}
              />
            </div>
            <div className="text-end mt-3">
              <button
                onClick={(e) => {
                  setShowModal(false);
                }}
                className="btn btn-sm btn-secondary"
              >
                Close
              </button>
              <button
                onClick={GenerateReport}
                className="btn btn-sm btn-info mx-1 "
              >
                Get Records
              </button>
              {selectedReport && (
                <AttReportBtn
                  selectedReport={selectedReport}
                  btnClasses={"btn btn-primary btn-sm"}
                />
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
