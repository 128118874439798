import Spinner from "components/Spinner";
import Toast from "components/Toast/ToastComponent";
import { setNotification } from "features/Notification/notificationSlice";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import authHeader from "services/auth-header";
import API from "services/axios";
import EmailGuestList from "./EmailGuestList";

export default function NotificationToCustomer({
  showModal,
  link,
  getLink,
  selectedDesign,
}) {
  const dispatch = useDispatch();

  /*   All States
   ********************************************* */
  const [loading, setLoading] = useState(false);
  const [customerContacts, setCustomerContacts] = useState(null);

  const [selectedProduct, setSelectedProduct] = useState(null);

  const [guestList, setGuestList] = useState([]);
  const [attachment, setAttachment] = useState(null);
  const [showGuestListModal, setShowGuestListModal] = useState(false);

  /*   All Functions
   ********************************************* */
  const loadingOn = () => {
    setLoading(true);
  };
  const loadingOff = () => {
    setLoading(false);
  };

  //get Data
  const getCustomerContacts = async () => {
    try {
      loadingOn();
      const { data } = await API.post(
        "/customer-contacts",
        { id: selectedDesign?.customerId },
        {
          headers: authHeader(),
        }
      );
      if (data.type === "success") {
        setCustomerContacts(data?.data);
      } else {
        dispatch(setNotification({ type: "error", message: data.message }));
      }
      loadingOff();
    } catch (err) {
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
      loadingOff();
    }
  };

  const sendEmail = async () => {
    try {
      if (!selectedDesign?.guestList) {
        dispatch(
          setNotification({
            type: "error",
            message: "No contacts in guest list",
          })
        );
        return;
      }

      loadingOn();
      const { data } = await API.post(
        "/mockup-notification",
        {
          guestList: selectedDesign?.guestList,
          attachment: attachment,
          mockupTitle: selectedDesign?.title,
          locationLogo: selectedDesign?.customer?.location?.logo,
          locationId: selectedDesign?.customer?.locationId,
          link,
        },
        {
          headers: authHeader(),
        }
      );
      if (data.type === "success") {
        dispatch(setNotification({ type: "success", message: data.message }));
      } else {
        dispatch(setNotification({ type: "error", message: data.message }));
      }
      loadingOff();

      showModal(false);
    } catch (err) {
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );

      loadingOff();
    }
  };

  const getProductById = (productId) => {
    let product = selectedDesign?.products?.find(
      (item) => item.id === productId
    );
    return product;
  };

  /*   All UseEffects
   ********************************************* */
  useEffect(() => {
    getLink({ showMsg: false });
    getCustomerContacts();
  }, []);

  if (!link) return <Spinner />;

  return (
    <div>
      {loading && <Spinner />}
      <div className="d-flex justify-content-between align-items-center">
        <h3>Send Update Notification</h3>
        {/* <div>
          <button
            onClick={() => {
              setShowGuestListModal(true);
            }}
            className="btn btn-sm btn-success me-1"
          >
            Guest List
          </button>
        </div> */}
      </div>
      <hr className="mt-1" />
      <div className="d-flex justify-content-between gap-2">
        <div className="flex-grow-1">
          <h4>Select Product</h4>
          <select
            defaultValue={""}
            onChange={(e) => {
              setSelectedProduct(parseInt(e.target.value));
            }}
            className="form-control"
          >
            <option value="">Select Product</option>
            {selectedDesign?.products &&
              selectedDesign?.products?.length &&
              selectedDesign?.products?.map((item) => {
                return (
                  <option key={item.id} value={item.id}>
                    {item?.title}
                  </option>
                );
              })}
          </select>
        </div>
        {selectedProduct && (
          <div className="flex-grow-1">
            <h4>Select Version</h4>
            <select
              defaultValue={""}
              onChange={(e) => {
                setAttachment(e.target.value);
              }}
              className="form-control"
            >
              <option value="">Select Product</option>
              {getProductById(selectedProduct)?.images &&
                getProductById(selectedProduct)?.images?.length &&
                getProductById(selectedProduct)?.images?.map((item) => {
                  return <option value={item?.img}>{item?.version}</option>;
                })}
            </select>
          </div>
        )}
      </div>
      <div className="d-flex justify-content-end gap-1 mt-3">
        <button
          onClick={() => {
            showModal(false);
          }}
          className="btn btn-sm btn-secondary"
        >
          Close
        </button>
        <button
          onClick={() => {
            sendEmail();
          }}
          disabled={!attachment}
          className="btn btn-sm btn-success"
        >
          Send
        </button>
      </div>
      {/*  Gust List
       ********************************************* */}
      <Modal
        contentClassName="bg-transparent"
        show={showGuestListModal}
        onHide={() => setShowGuestListModal(false)}
      >
        <Modal.Body
          className="shadow-lg bg-white px-3 "
          style={{ borderRadius: 15 }}
        >
          <EmailGuestList
            selectedDesign={selectedDesign}
            customerContacts={customerContacts}
            guestList={guestList}
            setGuestList={setGuestList}
            selectedCustomerId={selectedDesign?.customerId}
            showModal={setShowGuestListModal}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}
