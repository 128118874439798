import Spinner from "components/Spinner";
import Toast from "components/Toast/ToastComponent";
import { setNotification } from "features/Notification/notificationSlice";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import API from "services/axios";
import FabricSelectModal from "../FabricSelectModal";

export default function CustomProduct({
  selectedDesign,
  setSelectedDesign,
  setDesignRequests,
  setShowAddModal,
}) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user?.userInfo);
  /*   All States
   ********************************************* */
  const [formData, setFormData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [showFabricsModal, setShowFabricsModal] = useState(false);
  const [selectedFabric, setSelectedFabric] = useState(null);

  /*   All Functions
   ********************************************* */
  const uploadProduct = async (e, formData) => {
    e.preventDefault();

    if (!selectedFabric) {
      return dispatch(
        setNotification({
          type: "error",
          message: "Please select a fabric",
        })
      );
    }
    if (!formData?.file) {
      return dispatch(
        setNotification({
          type: "error",
          message: "Please upload file.",
        })
      );
    }
    if (!formData?.title) {
      return dispatch(
        setNotification({
          type: "error",
          message: "Please enter title.",
        })
      );
    }

    setIsLoading(true);

    try {
      const submitData = new FormData();
      submitData.append("file", formData?.file);

      let productId =
        selectedDesign?.products && selectedDesign?.products?.length
          ? selectedDesign?.products[selectedDesign?.products?.length - 1].id +
            1
          : 1;

      let folder = `kms/design-requests/${user?.companyId}/${selectedDesign?.id}/${productId}`;

      submitData.append("folder", folder);
      submitData.append("id", selectedDesign.id);

      // /* ******************** Updating currentData  ************************* */
      let imgUrl = `${folder}/${formData?.file?.name}`;
      let newImgData = [
        {
          img: imgUrl,
          version: 1,
        },
      ];

      let newProduct = {
        id: productId,
        title: formData?.title,
        hasPlayerNames: formData?.hasPlayerNames,
        hasNumbers: formData?.hasNumbers,
        description: formData?.description,
        fabrics: selectedFabric,
        images: newImgData,
        comments: [],
      };

      let updatedDesignProducts = selectedDesign?.products
        ? [...selectedDesign?.products, newProduct]
        : [newProduct];

      let updatedSelectedDesign = {
        ...selectedDesign,
        products: updatedDesignProducts,
      };

      submitData.append("products", JSON.stringify(updatedDesignProducts));

      /* ******************** Api Call  ************************* */
      const { data } = await API.post(
        "/design-request/new-version",
        submitData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      setSelectedDesign((prev) => updatedSelectedDesign);
      setDesignRequests((prev) => {
        return prev.map((item) => {
          if (item?.id === updatedSelectedDesign?.id) {
            return updatedSelectedDesign;
          } else {
            return item;
          }
        });
      });
      setIsLoading(false);
    } catch (err) {
      console.log(err.message);

      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );

      setIsLoading(false);
    }

    setShowAddModal(false);
  };

  /*   All UseEffects
   ********************************************* */
  return (
    <div>
      {isLoading && <Spinner />}
      <Toast />

      <h4 className="mt-3 mb-2">Upload New File</h4>
      <form
        onSubmit={(e) => {
          uploadProduct(e, formData);
        }}
      >
        <div className="row mb-2">
          <label className="col-5 my-auto">Style No</label>
          <div className="col-7 d-flex align-items-center gap-1">
            <input
              type="text"
              required
              onChange={(e) => {
                setFormData((prev) => {
                  return { ...prev, title: e.target.value };
                });
              }}
              className="form-control"
              placeholder="Enter Style No"
            />
          </div>
        </div>
        <div className="row mb-2">
          <label className="col-5 my-auto">Description</label>
          <div className="col-7 d-flex align-items-center gap-1">
            <input
              type="text"
              required
              onChange={(e) => {
                setFormData((prev) => {
                  return { ...prev, description: e.target.value };
                });
              }}
              className="form-control"
              placeholder="Enter Description"
            />
          </div>
        </div>

        <div className="row mb-3">
          <label className="col-5 my-auto">File</label>
          <div className="col-7 ">
            <input
              type="file"
              required
              onChange={(e) => {
                setFormData((prev) => {
                  return { ...prev, file: e.target.files[0] };
                });
              }}
              className="form-control"
              placeholder="Enter Title"
            />
          </div>
        </div>
        <div className="mb-2">
          <div className="row mb-1">
            <label className="col-5">Has Player Names?</label>
            <div className="col-7 ">
              <input
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    hasPlayerNames: e.target.checked,
                  })
                }
                checked={formData?.hasPlayerNames}
                type="checkbox"
                id="switch0"
                data-switch="success"
              />
              <label
                htmlFor="switch0"
                data-on-label="Yes"
                data-off-label="No"
              ></label>
            </div>
          </div>
          <div className="row mb-1">
            <label className="col-5">Has Numbers?</label>
            <div className="col-7 ">
              <input
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    hasNumbers: e.target.checked,
                  })
                }
                checked={formData?.hasNumbers}
                type="checkbox"
                id="switch1"
                data-switch="success"
              />
              <label
                htmlFor="switch1"
                data-on-label="Yes"
                data-off-label="No"
              ></label>
            </div>
          </div>
        </div>
        <div className="row mb-1">
          <label className="col-5 my-auto">Fabric</label>
          <div className="d-flex col-7 align-items-center gap-2">
            <button
              type="button"
              onClick={() => {
                setShowFabricsModal(true);
              }}
              className={`btn ${
                selectedFabric ? "btn-success" : "btn-secondary"
              } btn-sm`}
            >
              Select Fabric
            </button>
          </div>
        </div>
        {/*   buttons
         ********************************************* */}
        <div className="my-2">
          <div className="d-flex justify-content-end">
            <button
              onClick={() => {
                setShowAddModal(false);
              }}
              className="btn btn-secondary btn-sm"
              type="button"
            >
              Close
            </button>
            <button className="btn btn-primary btn-sm ms-1" type="submit">
              ADD
            </button>
          </div>
        </div>
      </form>
      <Modal
        size="sm"
        show={showFabricsModal}
        onHide={() => setShowFabricsModal(false)}
      >
        <Modal.Body
          className="custom-shadow bg-white px-3 "
          style={{ borderRadius: 15 }}
        >
          <FabricSelectModal
            selectedFabric={selectedFabric}
            showModal={setShowFabricsModal}
            setSelectedFabric={setSelectedFabric}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}
