import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
import API from "services/axios";
import { v4 as uuid } from "uuid";
import AudioRecorderComponent from "./AudioRecorderComponent";

export default function AudioMsgModal({
  selectedImg,
  selectedDesign,
  isDisabled,
  selectedProduct,
  designRequests,
  setSelectedProduct,
  setDesignRequests,
  showAudioModal,
  setShowAudioModal,
}) {
  const user = useSelector((state) => state.user?.userInfo);
  /*   All States
   ********************************************* */
  const [audioData, setAudioData] = useState(null);
  const [blob, setBlob] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const [deviceId, setDeviceId] = useState(null);
  const [devicesList, setDevicesList] = useState(null);
  const [selectedDevice, setSelectedDevice] = useState(null);

  /*   All Functions
   ********************************************* */

  const addAudioMessage = async (url) => {
    setIsLoading(true);
    const response = await fetch(url);
    const blob = await response.blob();

    if (blob) {
      // Create a FormData instance
      const formData = new FormData();

      // Append the blob as a file named 'audio'
      let fileName = `audio-${new Date()
        .toLocaleDateString()
        .replaceAll("/", "_")}_${new Date()
        .toLocaleTimeString()
        .replaceAll(":", "_")}.mp3`.replace(" ", "_");

      formData.append("audio", blob, fileName);

      let folder = `kms/design-requests/${user?.companyId}/${selectedDesign?.id}/${selectedProduct?.id}`;
      formData.append("folder", folder);
      formData.append("id", selectedDesign?.id);

      /* ******************** adding comment ************************* */

      let newComment = getNewComment(`${folder}/${fileName}`);

      let updatedProduct = {
        ...selectedProduct,
        comments: [...selectedProduct?.comments, newComment],
      };

      let updatedSelectedDesign = null;
      let updatedDesignRequest = designRequests.map((item) => {
        if (item.id === selectedDesign.id) {
          let updatedProducts = item.products.map((prod) => {
            if (prod.id === updatedProduct.id) {
              return updatedProduct;
            } else {
              return prod;
            }
          });

          let newItem = { ...item, products: updatedProducts };
          updatedSelectedDesign = newItem;
          return newItem;
        } else {
          return item;
        }
      });
      formData.append(
        "products",
        JSON.stringify(updatedSelectedDesign?.products)
      );

      try {
        const response = await API.post(
          "/design-request/audio-message",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        setSelectedProduct((prev) => updatedProduct);
        setDesignRequests(updatedDesignRequest);
        resetStates();
        setIsLoading(false);
        setShowAudioModal(false);
      } catch (error) {
        console.error(error);
      }
    } else {
      alert("no blob/audio added");
    }
  };

  const getNewComment = (comment) => {
    let rawDate = new Date();
    var ampm = rawDate.getHours() >= 12 ? "pm" : "am";

    let newDate = `${
      rawDate.getHours() > 12 ? rawDate.getHours() - 12 : rawDate.getHours()
    }:${rawDate.getMinutes()}${ampm} - ${rawDate.getDate()}-${rawDate.toLocaleDateString(
      "default",
      { month: "short" }
    )}-${rawDate.getFullYear()}`;

    let newComment = {
      id: uuid(),
      user:
        user.roles === "admin"
          ? `${user.firstName} ${user.lastName} V-${selectedImg?.version}`
          : `Designer (${user.id}) V-${selectedImg?.version}`,
      comment,
      type: "audio",
      imageVersion: selectedImg?.version,
      markerState: null,
      date: newDate,
    };
    return newComment;
  };

  const resetStates = () => {
    setAudioData(null);
    setBlob(null);
  };

  const getDeviceList = async () => {
    try {
      const perm = await navigator.mediaDevices.getUserMedia({ audio: true });
    } catch (err) {
      console.log(err.message);
      alert(err.message);
    }

    const devices = await navigator.mediaDevices.enumerateDevices();
    const uniqueMics = [];
    const microphones = devices.filter((device) => {
      if (
        device.kind === "audioinput" &&
        !uniqueMics.includes(device.groupId)
      ) {
        uniqueMics.push(device.groupId);
        return true;
      }
    });

    setDevicesList(microphones);
  };

  useEffect(() => {
    setSelectedDevice(null);
    setDeviceId(null);
  }, []);

  useEffect(() => {
    if (showAudioModal) getDeviceList();
  }, [showAudioModal]);

  /*   All UseEffects
   ********************************************* */

  return (
    <div className="">
      {/* <button
        onClick={() => {
          setShowAudioModal(true);
        }}
        className="btn btn-sm btn-success px-1"
      >
        Audio Msg
      </button> */}
      <button
        disabled={isDisabled}
        onClick={() => {
          setShowAudioModal(true);
        }}
        className="btn btn-info btn-sm"
      >
        <i className="mdi mdi-microphone"></i>
      </button>

      <Modal
        contentClassName="bg-transparent"
        show={showAudioModal}
        onHide={() => {
          setShowAudioModal(false);
          resetStates();
        }}
      >
        <Modal.Body className="bg-white custom-border-radius px-4">
          <div>
            {deviceId ? (
              <div className="mb-3">
                <div className="d-flex justify-content-between align-items-center">
                  <h3>Add Audio Message</h3>
                  <div>
                    <button
                      onClick={() => {
                        setDeviceId(null);
                        setSelectedDevice(null);
                      }}
                      className="btn btn-sm btn-primary"
                    >
                      Select Mic
                    </button>
                  </div>
                </div>
                {isLoading && <Spinner />}
                <div className=" mb-4 d-flex  align-items-start flex-column">
                  <label className="form-label mt-2">Record Audio</label>
                  {deviceId && (
                    <AudioRecorderComponent
                      deviceId={deviceId}
                      setShowAudioModal={setShowAudioModal}
                      addAudioMessage={addAudioMessage}
                      isLoading={isLoading}
                    />
                  )}
                </div>
              </div>
            ) : (
              <div>
                <h3 className="mb-4">Select Mic</h3>
                {devicesList?.map((device) => (
                  <div
                    role="button"
                    className={`bg-light custom-shadow-sm custom-border-radius-sm mb-3 p-2 px-3  ${
                      selectedDevice === device.deviceId &&
                      "border border-primary "
                    }`}
                    onClick={() => {
                      setSelectedDevice((prev) => device.deviceId);
                    }}
                    key={device.deviceId}
                  >
                    <div>{device.label}</div>
                  </div>
                ))}
                <div className="text-end mt-3">
                  <button
                    type="button"
                    onClick={() => {
                      setShowAudioModal(false);
                      // resetStates();
                    }}
                    className="btn btn-sm btn-secondary mx-1"
                  >
                    Close
                  </button>

                  <button
                    type="button"
                    disabled={!selectedDevice}
                    onClick={() => {
                      setDeviceId(selectedDevice);
                    }}
                    className="btn btn-sm btn-primary"
                  >
                    Select
                  </button>
                </div>
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
