import ArtImageWIthSignedUrl from "pages/artApproval/Mockups/components/ArtImageWIthSignedUrl";
import { useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";

export default function MProductsList({
  selectedDesign,
  setSelectedProduct,
  selectedProduct,
  setSelectedDesign,
  setDesignRequests,
}) {
  /*   All States
   ********************************************* */
  const [show, setShow] = useState(false);
  const [selectedProductIndex, setSelectedProductIndex] = useState(0);

  /*   All Functions
   ********************************************* */
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  /*   All UseEffects
   ********************************************* */
  useEffect(() => {
    if (selectedDesign?.products) {
      setSelectedProduct(
        (prev) => selectedDesign?.products[selectedProductIndex]
      );
    }
  }, [selectedDesign, selectedProductIndex]);
  return (
    <div>
      <button className="btn btn-primary btn-sm" onClick={handleShow}>
        Select Product
      </button>

      <Offcanvas show={show} onHide={handleClose} style={{ width: "55vw" }}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <h3>Products</h3>
            <hr className="m-0" />
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div
            style={{ height: "93%" }}
            className="scrollbar-hide overflow-scroll mb-3 px-1"
          >
            {selectedDesign?.products?.map((product, index) => (
              <div
                className="flex-grow-1 mb-2"
                onClick={() => {
                  setSelectedProductIndex((prev) => index);
                }}
                key={index}
              >
                <div
                  onClick={() => {
                    setSelectedProduct(product);
                    setSelectedProductIndex(index);
                    handleClose(true);
                  }}
                  className={`${
                    selectedProduct === product ? "bg-info" : "bg-light"
                  } flex-center custom-border-radius custom-shadow-sm p-2 position-relative`}
                  style={{ width: "auto", height: 100 }}
                >
                  <div className="btn btn-sm btn-primary position-absolute top-0 start-0">
                    {product?.id}
                  </div>
                  <ArtImageWIthSignedUrl
                    imgStyle={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                    imgClasses={"custom-border-radius-sm"}
                    path={product?.images[0].img}
                  />
                </div>
              </div>
            ))}
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
}
