import React, { useEffect, useState } from "react";
import MProductsList from "../MProductsList/MProductsList";
import MFooter from "../Footer/MFooter";
import axios from "axios";
import Spinner from "components/Spinner";

export default function MCenterButtons({
  selectedImg,
  selectedImgIndex,
  setSelectedImgIndex,
  setSelectedComment,
  selectedProduct,
  selectedDesign,
  setSelectedProduct,
  setSelectedDesign,
  setDesignRequests,
}) {
  /*   All States
   ********************************************* */

  const [loading, setLoading] = useState(false);

  const loadingOn = () => {
    setLoading(true);
  };
  const loadingOff = () => {
    setLoading(false);
  };

  /*   All Functions
   ********************************************* */
  const downloadFile = (fileKey) => {
    loadingOn();
    axios
      .post(`${process.env.REACT_APP_API_URI}/aws/images`, {
        key: fileKey,
      })
      .then((response) => {
        const url = response.data[0];
        loadingOff();
        const link = document.createElement("a");
        link.href = url;
        link.download = fileKey;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })

      .catch((error) => {
        console.log("Error downloading file:", error);
      });
  };

  /*   All UseEffects
   ********************************************* */

  return (
    <div className="d-flex flex-column align-items-start">
      {loading && <Spinner />}
      <h3>Product Detail</h3>
      <hr className="mb-2 mt-0 border border-1 w-50 border-secondary" />

      <div className="d-flex  gap-2 align-items-center">
        <div className="btn btn-sm btn-primary">{selectedProduct?.id}</div>
        <div className="me-3">
          <h3>{selectedProduct?.title}</h3>
        </div>
        <button
          type="button"
          onClick={() => {
            downloadFile(selectedProduct?.pdfCatalogue);
          }}
          disabled={!selectedProduct?.pdfCatalogue}
          className={`btn btn-sm ${
            selectedProduct?.pdfCatalogue ? "btn-success" : "btn-secondary"
          }`}
        >
          PDF Catalogue
        </button>
      </div>
      <h3 className="mt-3">Change Product</h3>
      <hr className="mb-2 mt-0 border border-1 w-50 border-secondary" />
      <div className="d-flex">
        <MProductsList
          selectedDesign={selectedDesign}
          setSelectedProduct={setSelectedProduct}
          selectedProduct={selectedProduct}
          setSelectedDesign={setSelectedDesign}
          setDesignRequests={setDesignRequests}
        />
      </div>
      <h3 className="mt-3">Product Options</h3>
      <hr className="mb-2 mt-0 border border-1 w-50 border-secondary" />

      {selectedProduct?.title && (
        <div className="d-flex">
          <div
            className={`d-flex gap-1 ${
              selectedProduct?.hasPlayerNames ? "bg-success" : "bg-danger"
            } bg-opacity-25 py-1 px-2 rounded`}
          >
            <div>Names:</div>
            <div>{selectedProduct?.hasPlayerNames ? "Yes" : "No"}</div>
          </div>
          <div
            className={`d-flex gap-1 mx-1 ${
              selectedProduct?.hasNumbers ? "bg-success" : "bg-danger"
            } bg-opacity-25 py-1 px-2 rounded`}
          >
            <div>Numbers:</div>
            <div>{selectedProduct?.hasNumbers ? "Yes" : "No"}</div>
          </div>
        </div>
      )}
    </div>
  );
}
