import Table from "components/DataTable/Table";
import Spinner from "components/Spinner";
import { setNotification } from "features/Notification/notificationSlice";
import DeleteModalButton from "pages/artApproval/Mockups/components/DeleteButton/DeleteModalButton";
import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";
import authHeader from "services/auth-header";
import API from "services/axios";

export default function Attendance() {
  const user = useSelector((state) => state.user?.userInfo);
  const [searchParams, setSearchParams] = useSearchParams();

  const dispatch = useDispatch();
  /*   ALL STATES
   ********************************************* */
  const [allAttendanceUsers, setAllAttendanceUsers] = useState(null);
  const [isRefreshing, setIsRefreshing] = useState(false);

  const [showDevicesList, setShowDevicesList] = useState(false);

  const [devicesList, setDevicesList] = useState(null);
  const [formData, setFormData] = useState(null);

  const [isUpdateMode, setIsUpdateMode] = useState(false);

  const [loading, setLoading] = useState(false);

  const loadingOn = () => {
    setLoading(true);
  };
  const loadingOff = () => {
    setLoading(false);
  };

  /*   All Functions
   ********************************************* */
  //get Data
  const getAttendanceData = async (id) => {
    loadingOn();
    try {
      const { data } = await API.get("/get-attendance/" + id, {
        headers: authHeader(),
      });
      setIsRefreshing(false);
      loadingOff();
    } catch (err) {
      loadingOff();
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
    }
  };

  const getUsersData = async (id) => {
    loadingOn();
    try {
      const { data } = await API.get("/get-attendance-users/" + id, {
        headers: authHeader(),
      });

      setAllAttendanceUsers(data);
      loadingOff();
    } catch (err) {
      loadingOff();
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
    }
  };

  /*   Device Select Functions
   ********************************************* */
  const getDevicesList = async () => {
    try {
      loadingOn();
      const { data } = await API.get("/get-attendance-devices", {
        headers: authHeader(),
      });

      setDevicesList(data);
      loadingOff();
    } catch (err) {
      loadingOff();
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
    }
  };

  const handleDeviceSelect = (device) => {
    // Set deviceId=device.id in url params using useSearchParams
    setSearchParams({ deviceId: device.id });
    setShowDevicesList(false);
  };

  const handleUpdateClick = (device) => {
    setFormData(device);
    setIsUpdateMode(true);
  };

  const addDevice = async (e) => {
    e.preventDefault();
    try {
      loadingOn();
      const { data } = await API.post("/add-attendance-devices", formData, {
        headers: authHeader(),
      });
      setDevicesList(data);
      setFormData(data);
      loadingOff();
    } catch (err) {
      loadingOff();
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
    }
  };

  // Update Device
  const updateDevice = async () => {
    try {
      loadingOn();
      const { data } = await API.patch(
        "/patch-attendance-devices/" + formData.id,
        formData,
        { headers: authHeader() }
      );
      if (data?.isSuccess) {
        setDevicesList(data.data);
        setFormData({});
        setIsUpdateMode(false);
        dispatch(
          setNotification({
            message: "Updated Successfully",
            type: "success",
          })
        );
      }
      loadingOff();
    } catch (err) {
      loadingOff();
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
    }
  };

  // Delete Device
  const deleteDevice = async (id) => {
    try {
      loadingOn();
      const { data } = await API.delete("/delete-attendance-device/" + id, {
        headers: authHeader(),
      });

      if (data?.isSuccess) {
        dispatch(
          setNotification({
            message: data.message,
            type: "success",
          })
        );
        await getDevicesList();
      } else {
        dispatch(
          setNotification({
            message: data.message,
            type: "error",
          })
        );
      }
      loadingOff();
    } catch (err) {
      loadingOff();
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
    }
  };

  const handleTestConnection = async (device) => {
    try {
      loadingOn();
      const { data } = await API.get(
        "/test-device-connection/" + device.id,
        formData,
        {
          headers: authHeader(),
        }
      );

      if (data?.isSuccess) {
        dispatch(
          setNotification({
            message: data.message,
            type: "success",
          })
        );
      } else {
        dispatch(
          setNotification({
            message: data.message,
            type: "error",
          })
        );
      }

      // setDevicesList(data);
      // setFormData(data);
      loadingOff();
    } catch (err) {
      loadingOff();
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
    }
  };

  /*   All UseEffects
   ********************************************* */

  useEffect(() => {
    // If not deviceId in search params then get devices list
    let deviceId = searchParams.get("deviceId");
    if (!deviceId) {
      setShowDevicesList(true);
      let clearTimeoutId = setTimeout(() => {
        getDevicesList();
      }, 100);
      return () => clearTimeout(clearTimeoutId);
    } else {
      const timeout = setTimeout(() => getUsersData(parseInt(deviceId)), 0.5);
      return () => clearTimeout(timeout);
    }
  }, [searchParams]);

  useEffect(() => {
    let deviceId = searchParams.get("deviceId");
    if (isRefreshing && deviceId) {
      let clearTimeout = getAttendanceData();
      return () => clearTimeout(clearTimeout);
    }
  }, [isRefreshing, searchParams]);

  /*   Table functions
   ********************************************* */
  const btn1 = () => (
    <div>
      {(user?.permissions?.includes("work_orders_add") ||
        user?.roles === "admin") && (
        <>
          <button
            onClick={() => setIsRefreshing((prev) => true)}
            type="button"
            disabled={!searchParams.get("deviceId")}
            className="btn btn-primary  ms-1"
          >
            Sync Database
          </button>
        </>
      )}
    </div>
  );

  const buttons = () => (
    <div className=" d-flex justify-content-end">
      <button
        onClick={(e) => {
          setShowDevicesList(true);
        }}
        className="btn btn-info mx-1"
      >
        Get Devices List
      </button>
    </div>
  );
  const table_head = ["ID", "User Name", "Role", "Card No"];

  const table_body = (item, index) => (
    <tr className="" key={item.id}>
      <td>{item.userId}</td>
      <td>{item.name}</td>
      <td>{item.role === "14" ? "Admin" : "User"}</td>
      <td>{item.cardno}</td>
      <td className="table-action">
        <Link
          to={`/attendance/user/${searchParams.get("deviceId")}/${
            item?.userId
          }/`}
          className="btn btn-sm btn-info"
        >
          Details
        </Link>
      </td>
    </tr>
  );

  return (
    <div
      className=""
      style={{
        marginLeft: 280,
        height: "90vh",
        paddingTop: 10,
        paddingRight: 30,
      }}
    >
      {loading && <Spinner />}
      <h1 className="mb-3">Attendance</h1>
      {/*   Table
       ********************************************* */}
      <div
        className="px-2 flex-grow-1 d-flex"
        style={{ minHeight: "50vh", borderRadius: 15 }}
      >
        <div className="horizontal-scrollable d-flex flex-grow-1">
          <Table
            buttons={buttons}
            btn1={btn1}
            table_head={table_head}
            table_body={table_body}
            table_data={allAttendanceUsers}
          />
        </div>
      </div>

      <Modal
        show={showDevicesList}
        onHide={(e) => {
          setShowDevicesList(false);
        }}
        contentClassName="bg-transparent"
        size="lg"
      >
        <Modal.Body className="bg-white custom-border-radius custom-shadow ps-3">
          <h3>Devices List</h3>
          <hr className="mt-0" />

          <div className="my-3">
            <h4>Add Device</h4>
            <form className="pt-2">
              <div className="d-flex align-items-end gap-2">
                <div>
                  <label htmlFor="name">Name</label>
                  <input
                    type="text"
                    value={formData?.name || ""}
                    onChange={(e) => {
                      setFormData((prev) => {
                        return { ...prev, name: e.target.value };
                      });
                    }}
                    placeholder="Enter Name"
                    className="form-control"
                  />
                </div>
                <div>
                  <label htmlFor="name">Ip Address</label>
                  <input
                    type="text"
                    value={formData?.ipAddress || ""}
                    onChange={(e) => {
                      setFormData((prev) => {
                        return { ...prev, ipAddress: e.target.value };
                      });
                    }}
                    placeholder="Enter Ip Address"
                    className="form-control"
                  />
                </div>
                <div style={{ width: "20%" }}>
                  <label htmlFor="name">Port ( Optional )</label>
                  <input
                    type="text"
                    placeholder="4370"
                    value={formData?.port || ""}
                    onChange={(e) => {
                      setFormData((prev) => {
                        return { ...prev, port: e.target.value };
                      });
                    }}
                    className="form-control"
                  />
                </div>
                <div>
                  {!isUpdateMode ? (
                    <button
                      type="button"
                      onClick={addDevice}
                      className="btn btn-primary"
                    >
                      ADD
                    </button>
                  ) : (
                    <>
                      <button
                        type="button"
                        onClick={updateDevice}
                        className="btn btn-success me-1"
                      >
                        Update
                      </button>
                      <button
                        onClick={() => {
                          setFormData({});
                          setIsUpdateMode(false);
                        }}
                        className="btn btn-warning"
                      >
                        Cancel
                      </button>
                    </>
                  )}
                </div>
              </div>
            </form>
          </div>
          <h4 className="mt-4">Devices List</h4>
          <table className="table">
            <thead>
              <tr>
                <th scope="col">ID</th>
                <th scope="col">Name</th>
                <th scope="col">Ip</th>
                <th scope="col">Port</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {devicesList?.map((device) => (
                <tr key={device.id}>
                  <th scope="row">{device.id}</th>
                  <td>{device.name}</td>
                  <td>{device.ipAddress}</td>
                  <td>{device.port}</td>
                  <td>
                    <button
                      onClick={(e) => {
                        handleDeviceSelect(device);
                      }}
                      className="btn btn-sm btn-success"
                    >
                      Select
                    </button>
                    <button
                      onClick={(e) => {
                        handleTestConnection(device);
                      }}
                      className="btn mx-1 btn-sm btn-success"
                    >
                      Test Connection
                    </button>

                    <button
                      onClick={(e) => {
                        handleUpdateClick(device);
                      }}
                      className="btn btn-sm btn-info me-1"
                    >
                      <i className="mdi mdi-pencil"></i>
                    </button>

                    <DeleteModalButton
                      deleteFunc={deleteDevice}
                      deleteParams={device.id}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="text-end me-3">
            <button
              onClick={(e) => {
                setShowDevicesList(false);
              }}
              className="btn btn-sm btn-secondary"
            >
              Close
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
