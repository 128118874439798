import Spinner from "components/Spinner";
import { setNotification } from "features/Notification/notificationSlice";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import authHeader from "services/auth-header";
import API from "services/axios";

export default function AddDesignRequest({
  customerData,
  setShowAddModal,
  getDesignRequests,
}) {
  const dispatch = useDispatch();

  /*   All States
   ********************************************* */
  const [searchResults, setSearchResults] = useState(null);
  const [searchText, setSearchText] = useState("");

  const [formData, setFormData] = useState({ title: "" });

  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [isAddLoading, setIsAddLoading] = useState(null);

  const [showCustomerContactModal, setShowCustomerContactModal] =
    useState(false);

  /*   All Functions
   ********************************************* */

  const handleSelectedCustomer = async (customerData) => {
    setSelectedCustomer(customerData);
    setSearchText("");
  };

  const handleAddDesign = async (e, formData) => {
    e.preventDefault();
    setIsAddLoading(true);

    try {
      await API.post(
        "/design-request/upload",
        {
          ...formData,
          approved: false,
          locationId: selectedCustomer?.locationId,
        },
        {
          headers: authHeader(),
        }
      );
      await getDesignRequests();
      setIsAddLoading(false);
    } catch (err) {
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
      setIsAddLoading(false);
    }

    setShowAddModal(false);
  };

  /*   All UseEffects
   ********************************************* */
  useEffect(() => {
    let results = customerData?.filter((item) => {
      return (
        item.id.toString().includes(searchText.toString()) ||
        item.customerName
          .toLowerCase()
          .includes(searchText.toString().toLowerCase())
      );
    });
    setSearchResults(results);

    return () => {
      setSearchResults(null);
    };
  }, [searchText]);

  return (
    <div>
      {isAddLoading && <Spinner />}
      <div className="">
        <h3>ADD MOCKUP </h3>
      </div>
      <hr className="mt-0" />
      <form
        onSubmit={(e) => {
          handleAddDesign(e, {
            ...formData,
            customerId: selectedCustomer?.id,
          });
        }}
      >
        {/*   Customer Selection
         ********************************************* */}
        <div className="">
          {!selectedCustomer && (
            <>
              <div className="fw-bold fs-4 mb-1 px-1">Select Customer</div>
              <div className="d-flex align-items-center mb-3">
                <div className="flex-grow-1 ">
                  <input
                    className="form-control bg-white shadow-sm"
                    placeholder="Search Customer"
                    type="search"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                  />
                </div>
              </div>
            </>
          )}
          {selectedCustomer && (
            <div className="my-3">
              <div className="fw-bold fs-4 mb-1 px-1">Selected Customer</div>
              <div
                className="bg-white border-primary border py-2 shadow-lg"
                style={{ borderRadius: 8 }}
              >
                <div className="row ps-3 pe-1">
                  <div className="col-2">{selectedCustomer?.id}</div>
                  <div className="col-8">{selectedCustomer?.customerName}</div>
                  <div className="col-2">
                    <div
                      onClick={() => setSelectedCustomer(null)}
                      className="btn btn-sm btn-danger p-0 px-1"
                    >
                      <i className="mdi mdi-delete"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {searchText && (
            <div
              className="bg-white px-2 shadow-lg scroll py-2 mt-1"
              style={{
                maxHeight: "50%",
                scrollBehavior: "auto",
                borderRadius: 10,
              }}
            >
              {searchResults?.map((item, index) => (
                <div key={index} className="px-2">
                  <div
                    className={` rounded py-1  ${
                      item.customerName.toLowerCase() ===
                      searchText.toLowerCase()
                        ? "bg-primary bg-opacity-25"
                        : ""
                    } row`}
                  >
                    <div className="col-2">{item?.id}</div>
                    <div className="col-8">{item?.customerName}</div>
                    <div className="col-2">
                      <div
                        onClick={() => handleSelectedCustomer(item)}
                        className="btn btn-sm btn-success p-0 px-1"
                      >
                        Select
                      </div>
                    </div>
                  </div>
                  {!(item.id === searchResults[searchResults.length - 1].id) &&
                    !(searchResults.length === 1) && <hr className="my-0 " />}
                </div>
              ))}
            </div>
          )}
        </div>

        <hr />

        <div className="mb-2">
          <label className="form-label my-auto">Title</label>
          <div className="">
            <input
              type="text"
              required
              value={formData?.title}
              onChange={(e) => {
                setFormData((prev) => {
                  return { ...prev, title: e.target.value };
                });
              }}
              className="form-control"
              placeholder="Enter Title"
            />
          </div>
        </div>

        {/*   buttons
         ********************************************* */}
        <div className="my-2">
          <div className="d-flex justify-content-end">
            <button
              onClick={() => {
                setShowAddModal(false);
              }}
              className="btn btn-secondary btn-sm"
              type="button"
            >
              Close
            </button>
            <button
              disabled={!selectedCustomer || isAddLoading}
              className="btn btn-primary btn-sm ms-1"
              type="submit"
            >
              ADD
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
