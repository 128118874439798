import { setNotification } from "features/Notification/notificationSlice";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import authHeader from "services/auth-header";
import API from "services/axios";

export default function UpdateCustomer({
  refreshFunc,
  setIsLoading,
  showModal,
  selectedDesign,
  setSelectedDesign,
}) {
  const dispatch = useDispatch();

  /*   All States
   ********************************************* */
  const [customersList, setCustomersList] = useState(null);

  const [searchResults, setSearchResults] = useState(null);
  const [searchText, setSearchText] = useState("");

  const [title, setTitle] = useState(selectedDesign?.title);
  const [selectedCustomer, setSelectedCustomer] = useState(null);

  /*   All Functions
   ********************************************* */
  const getCustomersData = async () => {
    try {
      const { data } = await API.get("/customers", { headers: authHeader() });
      setCustomersList(data);
    } catch (err) {
      alert(err.message);
    }
  };

  const handleSelectedCustomer = (item) => {
    setSelectedCustomer(item);
  };

  const updateMockupCustomer = async () => {
    try {
      if (
        title === selectedDesign?.title &&
        selectedCustomer &&
        selectedCustomer?.id === selectedDesign?.customerId
      ) {
        showModal(false);
        return;
      }
      setIsLoading(true);
      const { data } = await API.patch(
        "/design-request/update",
        {
          formData: {
            id: selectedDesign?.id,
            title: title,
            customerId: selectedCustomer
              ? selectedCustomer?.id
              : selectedDesign?.customerId,
          },
        },
        { headers: authHeader() }
      );

      if (data?.success) {
        let mockupLists = await refreshFunc();
        let selectedMockup = mockupLists?.find(
          (item) => item.id === selectedDesign?.id
        );
        setSelectedDesign(selectedMockup);
        setIsLoading(false);
        showModal(false);
      }
    } catch (err) {
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
    }
  };

  /*   All UseEffects
   ********************************************* */
  useEffect(() => {
    getCustomersData();
  }, []);

  useEffect(() => {
    let results = customersList?.filter((item) => {
      return (
        item.id.toString().includes(searchText.toString()) ||
        item.customerName
          .toLowerCase()
          .includes(searchText.toString().toLowerCase())
      );
    });
    setSearchResults(results);

    return () => {
      setSearchResults(null);
    };
  }, [searchText]);
  return (
    <div>
      <div className="mb-3">
        <h3>Change Customer</h3>
      </div>
      {/*   Customer Selection
       ********************************************* */}
      <div className="mb-2">
        <label htmlFor="title">Title</label>
        <input
          type="text"
          name="title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          className="form-control"
          placeholder="Enter Title"
        />
      </div>
      <div className="">
        {!selectedCustomer && (
          <>
            <div className="fw-bold fs-4 mb-1 px-1">Select Customer</div>
            <div className="d-flex align-items-center mb-3">
              <div className="flex-grow-1 ">
                <input
                  className="form-control bg-white shadow-sm"
                  placeholder="Search Customer"
                  type="search"
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                />
              </div>
            </div>
          </>
        )}
        {selectedCustomer && (
          <div className="my-3">
            <div className="fw-bold fs-4 mb-1 px-1">Selected Customer</div>
            <div
              className="bg-white border-primary border py-2 shadow-lg"
              style={{ borderRadius: 8 }}
            >
              <div className="row ps-3 pe-1">
                <div className="col-2">{selectedCustomer?.id}</div>
                <div className="col-8">{selectedCustomer?.customerName}</div>
                <div className="col-2">
                  <div
                    onClick={() => setSelectedCustomer(null)}
                    className="btn btn-sm btn-danger p-0 px-1"
                  >
                    <i className="mdi mdi-delete"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {searchText && (
          <div
            className="bg-white px-2 shadow-lg scroll py-2 mt-1"
            style={{
              maxHeight: "50%",
              scrollBehavior: "auto",
              borderRadius: 10,
            }}
          >
            {searchResults?.map((item, index) => (
              <div key={index} className="px-2">
                <div
                  className={` rounded py-1  ${
                    item.customerName.toLowerCase() === searchText.toLowerCase()
                      ? "bg-primary bg-opacity-25"
                      : ""
                  } row`}
                >
                  <div className="col-2">{item?.id}</div>
                  <div className="col-8">{item?.customerName}</div>
                  <div className="col-2">
                    <div
                      onClick={() => handleSelectedCustomer(item)}
                      className="btn btn-sm btn-success p-0 px-1"
                    >
                      Select
                    </div>
                  </div>
                </div>
                {!(item.id === searchResults[searchResults.length - 1].id) &&
                  !(searchResults.length === 1) && <hr className="my-0 " />}
              </div>
            ))}
          </div>
        )}
        {/*   Buttons
         ********************************************* */}
        <div className="d-flex justify-content-end align-items-center gap-1 mt-3">
          <button
            onClick={() => {
              showModal(false);
            }}
            className="btn btn-secondary"
          >
            Close
          </button>
          <button onClick={updateMockupCustomer} className="btn btn-primary">
            Update
          </button>
        </div>
      </div>
    </div>
  );
}
