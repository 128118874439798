import Table from "components/DataTable/Table";
import Spinner from "components/Spinner";
import Toast from "components/Toast/ToastComponent";
import { setNotification } from "features/Notification/notificationSlice";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import authHeader from "services/auth-header";
import API from "services/axios";
import ImageWIthSignedUrl from "../../../../ImageWIthSignedUrl";

export default function ChooseCatalogueProduct({
  showModal,
  setFile,
  setImagePreview,
  setSelectedCatalogueProduct,
}) {
  const dispatch = useDispatch();

  /*   All States
   ********************************************* */
  const [loading, setLoading] = useState(false);
  const [mockupCatalogue, setMockupCatalogue] = useState(null);

  const loadingOn = () => {
    setLoading(true);
  };
  const loadingOff = () => {
    setLoading(false);
  };

  /*   All Functions
   ********************************************* */
  const getMockupCatalogue = async () => {
    try {
      loadingOn();
      const { data } = await API.get("/mockup-catalogues", {
        headers: authHeader(),
      });
      setMockupCatalogue(data);
      loadingOff();
    } catch (err) {
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
      loadingOff();
    }
  };
  /*   Table functions
   ********************************************* */

  const table_head = [
    "id",
    "Style No#",
    "Description",
    "Product Image",
    "Actions",
  ];

  const table_body = (item, index) => (
    <tr className="" key={item.id}>
      <td>{item.id}</td>
      <td>{item.styleNo}</td>
      <td>{item.description}</td>
      <td>
        {item.productImage ? (
          <ImageWIthSignedUrl path={item?.productImage} width={50} />
        ) : (
          "No"
        )}
      </td>
      <td className="table-action">
        <button
          onClick={() => {
            setSelectedCatalogueProduct({
              key: item.productImage,
              pdfCatalogue: item?.pdfCatalogue,
              styleNo: item?.styleNo,
              description: item?.description,
              fabrics: item?.fabrics,
              categories: item?.categories,
            });
            setFile(null);
            setImagePreview(null);

            setTimeout(() => {
              showModal(false);
            }, 300);
          }}
          className="btn btn-success btn-sm"
        >
          Select
        </button>
      </td>
    </tr>
  );

  /*   All UseEffects
   ********************************************* */
  useEffect(() => {
    getMockupCatalogue();
  }, []);
  return (
    <div>
      {loading && <Spinner />}
      <Toast />
      <h3 className="mb-4">Mockups Catalogue</h3>
      {/*   Table
       ********************************************* */}
      <div
        className="px-2 flex-grow-1 d-flex"
        style={{ minHeight: "50vh", borderRadius: 15 }}
      >
        <div className="horizontal-scrollable d-flex flex-grow-1">
          <Table
            table_head={table_head}
            table_body={table_body}
            table_data={mockupCatalogue}
          />
        </div>
      </div>
    </div>
  );
}
